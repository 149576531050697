import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { toast } from "react-toastify";
import {
  OrderStatusType,
  OrderStatusWithApplying,
  ReadonlyResponse,
  ReadonlyResponseWithId,
  SearchTextWIthPaginationParams,
} from "../utils/types/general_type";
import {
  documentKeys,
  itemKeys,
  orderKeys,
  orderMessageRoomKeys,
  userKeys,
} from "../utils/query-key";
import { ItemType } from "../utils/types/item_type";
import { conversionItemName } from "../utils/conversion";
import { FIVE_MINUTES, PAGE_SIZE } from "../utils/constant";
import { Woods } from "./Wood/type";
import { Logs } from "./Log/type";
import { GetBlueprints } from "./Blueprint/type";
import { UUID } from "crypto";

interface CreateOrder {
  itemName: ItemType;
  itemId: number;
  isAll: boolean;
  userIds: UUID[];
}

export function useCreateOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: CreateOrder) => {
      const { token } = await getAccessToken();
      // userIdsが不要な場合はnullにする
      const userIds = data.isAll ? undefined : data.userIds;

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/${data.itemName}_order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            item_id: data.itemId,
            is_all: data.isAll,
            user_ids: userIds, // userIdsがundefinedなら送信しない
          }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (res, variables) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: itemKeys.items_by_filter({
          itemName: variables.itemName,
          isTemporarilyStored: false,
          isOrdered: true,
          page: 1,
          pageSize: PAGE_SIZE,
        }),
      });
      await queryClient.invalidateQueries({
        queryKey: orderKeys.searchStatuses({
          type: variables.itemName,
          searchText: "",
          page: 1,
          pageSize: PAGE_SIZE,
          statuses: [],
        }),
      });
    },
  });
}

interface ReceiveOrderRequest {
  id: number;
  type: ItemType;
  status: OrderStatusType;
}

export function useReceiveOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, type, status }: ReceiveOrderRequest) => {
      const { token } = await getAccessToken();

      const response = await fetch(`${config.backendUrl}/api/${type}_order`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({ id, status }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: userKeys.order({ page: 1, pageSize: PAGE_SIZE }),
      });
      await queryClient.invalidateQueries({
        queryKey: documentKeys.quotations({ page: 1, pageSize: PAGE_SIZE }),
      });
      toast.success(
        `${conversionItemName(variables.type)}リストを${variables.status === "receiving" ? "受注" : variables.status === "pending" ? "保留" : variables.status === "denying" && "辞退"}しました`,
      );
    },
  });
}

export interface SearchPageRequestWithStatus
  extends SearchTextWIthPaginationParams {
  statuses: OrderStatusWithApplying[]; // 配列に変更
  type: ItemType;
}
interface SearchOrderStatusesResponse {
  items: Woods | Logs | GetBlueprints;
  totalPages: number;
}
export function useSearchOrderStatuses({
  type,
  searchText,
  page,
  pageSize = PAGE_SIZE,
  statuses, // statuses は OrderStatusWithApplying[] 型
}: SearchPageRequestWithStatus) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: orderKeys.searchStatuses({
      type,
      searchText,
      statuses,
      page,
      pageSize,
    }), // クエリキーに statuses を追加
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      if (searchText) {
        params.append("search_text", searchText);
      }
      params.append("page", `${page}`);
      params.append("page_size", `${pageSize}`);
      // statuses が配列の場合、各ステータスを個別に追加
      statuses.forEach((s) => params.append("statuses", s));
      const response = await fetch(
        `${config.backendUrl}/api/status/order/${type}s?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<
        Readonly<SearchOrderStatusesResponse>
      >;
    },
    staleTime: FIVE_MINUTES,
  });
}

interface ItemContractRequest {
  type: ItemType;
  orderId: number;
  itemId: number;
}
export function useContractOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (req: ItemContractRequest) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/${req.type}_order/contract`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            order_id: req.orderId,
            item_id: req.itemId,
          }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponseWithId>;
    },
    onSuccess: async (res, variables) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: orderMessageRoomKeys.search({
          searchText: "",
          page: 1,
          pageSize: PAGE_SIZE,
        }),
      });
      await queryClient.invalidateQueries({
        queryKey: orderKeys.searchStatuses({
          type: variables.type,
          searchText: "",
          page: 1,
          pageSize: PAGE_SIZE,
          statuses: [],
        }),
      });
      await queryClient.invalidateQueries({
        queryKey: documentKeys.orderForms({ page: 1, pageSize: PAGE_SIZE }),
      });
      await queryClient.invalidateQueries({
        queryKey: itemKeys.items_by_filter({
          itemName: variables.type,
          isTemporarilyStored: false,
          isOrdered: true,
          page: 1,
          pageSize: PAGE_SIZE,
        }),
      });
    },
  });
}

interface OrderApplyRequest {
  type: ItemType;
  orderId: number;
}
export function useApplyForOrderAgain() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (req: OrderApplyRequest) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/${req.type}_order/again`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            order_id: req.orderId,
          }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponseWithId>;
    },
    onSuccess: async (res, variables) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: orderKeys.searchStatuses({
          type: variables.type,
          searchText: "",
          page: 1,
          pageSize: PAGE_SIZE,
          statuses: [],
        }),
      });
    },
  });
}

interface IssueRequest {
  messageRoomId: number;
  type?: ItemType;
}

export function useDeliveryNoteOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ messageRoomId, type }: IssueRequest) => {
      const { token } = await getAccessToken();

      const response = await fetch(
        `${config.backendUrl}/api/${type}_order/delivery_note`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({ messageRoomId }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: orderMessageRoomKeys.orderMessageRoom(
          variables.messageRoomId,
        ),
      });
      await queryClient.invalidateQueries({
        queryKey: documentKeys.deliveryNotes({ page: 1, pageSize: PAGE_SIZE }),
      });
      toast.success(
        `${conversionItemName(variables.type)}の納品書を発行しました`,
      );
    },
  });
}
export function useInvoiceOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ messageRoomId, type }: IssueRequest) => {
      const { token } = await getAccessToken();

      const response = await fetch(
        `${config.backendUrl}/api/${type}_order/invoice`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({ messageRoomId }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: orderMessageRoomKeys.orderMessageRoom(
          variables.messageRoomId,
        ),
      });
      await queryClient.invalidateQueries({
        queryKey: documentKeys.invoices({ page: 1, pageSize: PAGE_SIZE }),
      });
      toast.success(
        `${conversionItemName(variables.type)}の請求書を発行しました`,
      );
    },
  });
}

interface OrderCancelRequest {
  type: ItemType;
  itemId: number;
}
export function useCancelOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ itemId, type }: OrderCancelRequest) => {
      const { token } = await getAccessToken();
      const response = await fetch(`${config.backendUrl}/api/${type}_order`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({ itemId }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (res, variables) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: itemKeys.items_by_filter({
          itemName: variables.type,
          isTemporarilyStored: false,
          isOrdered: true,
          page: 1,
          pageSize: PAGE_SIZE,
        }),
      });
      await queryClient.invalidateQueries({
        queryKey: itemKeys.items_by_filter({
          itemName: variables.type,
          isTemporarilyStored: true,
          isOrdered: false,
          page: 1,
          pageSize: PAGE_SIZE,
        }),
      });
      await queryClient.invalidateQueries({
        queryKey: orderKeys.searchStatuses({
          type: variables.type,
          searchText: "",
          statuses: [],
          page: 1,
          pageSize: PAGE_SIZE,
        }),
      });
    },
  });
}
