import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { SearchBox } from "../../../uis/TextField/SearchBox";
import { Error } from "../../../uis/Error";
import Avatar from "../../../uis/Avatar";
import { useNavigate } from "react-router-dom";
import { UnreadBadge } from "../../../uis/Badge";
import { useSearchOrderMessageRooms } from "../../../hooks/useOrderMessageRoomHook";
import { useReadOrderMessage } from "../../../hooks/useOrderMessageHook";
import { ItemType } from "../../../utils/types/item_type";
import { PaginationComponent } from "../../../uis/Pagination";
import { useSearchAndPaginationHook } from "../../../hooks/useSearchAndPaginationHook";

export const OrderMessageRooms = () => {
  const navigate = useNavigate();
  // カスタムフック
  const {
    page,
    searchText,
    searchParams,
    handlePageChange,
    handleSearchChange,
  } = useSearchAndPaginationHook();
  const [searchClicked, setSearchClicked] = useState(false); // 検索ボックスがクリックされたかを管理
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { data, error } = useSearchOrderMessageRooms({
    searchText,
    page,
  });

  const { mutateAsync: mutateReadAsync } = useReadOrderMessage();

  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev); // 現在の値とは反対の値を設定
  };

  const handleClick = async (roomId: number, type: ItemType) => {
    try {
      await mutateReadAsync({
        type,
        messageRoomId: roomId,
      });
      // 現在のクエリパラメータを取得
      const currentSearchParams = Object.fromEntries(searchParams.entries());
      const queryString = new URLSearchParams(currentSearchParams).toString();
      const navigatePath = `/talk/${type}_order_message_room/${roomId}${
        queryString ? `?${queryString}` : ""
      }`;

      navigate(navigatePath);
    } catch (error) {
      console.error(error);
    }
  };

  // エラー時の処理
  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <Box
      sx={{
        width: "95%",
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
        height: "85vh", // 全体コンテナの高さを指定
      }}
    >
      {/* 検索ボックスはスクロール外に固定 */}
      <SearchBox
        placeholder="会社・プロジェクトを検索"
        isSmallScreen
        onClick={handleSearchBoxClick}
        onChange={handleSearchChange}
        value={searchText}
        searchClicked={searchClicked}
      />
      {/* リスト部分にのみスクロールを適用 */}
      <Box
        sx={{
          flex: 1, // 残りの縦スペースをすべて使用
          overflowY: "auto", // 縦方向にスクロール
          width: "100%",
          mt: 2,
        }}
      >
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {data &&
          data.orderMessageRooms &&
          data.orderMessageRooms.length > 0 ? (
            data.orderMessageRooms.map((room) => (
              <ListItem
                key={`${room.id}-${room.type}`}
                alignItems="flex-start"
                onClick={() => handleClick(room.id, room.type)}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  padding: theme.spacing(2),
                  borderBottom: `1px solid ${theme.palette.divider}`,
                }}
              >
                <ListItemAvatar>
                  <Avatar user={room.user} isSmallScreen={isSmallScreen} />
                </ListItemAvatar>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    marginLeft: theme.spacing(2),
                  }}
                >
                  <Typography
                    variant={isSmallScreen ? "subtitle1" : "h6"}
                    component="div"
                    color="text.primary"
                    sx={{ fontWeight: "bold", mb: 0.5 }}
                  >
                    {room.user.company_name}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                    sx={{ mb: 0.5 }}
                  >
                    プロジェクトのタイトル: {room.title}
                  </Typography>
                  {room.message && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        mt: 1,
                      }}
                    >
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        最終メッセージ: {room.message.content}
                      </Typography>
                      <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                        sx={{ mt: 0.5 }}
                      >
                        {room.message.createdAt.toLocaleString()}
                      </Typography>
                    </Box>
                  )}
                </Box>
                {/* 未読件数の表示 */}
                {room.unreadCount > 0 && (
                  <UnreadBadge
                    badgeContent={room.unreadCount}
                    color="error"
                    overlap="circular"
                  />
                )}
              </ListItem>
            ))
          ) : (
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mt: 2, textAlign: "center" }}
            >
              メッセージルームが見つかりません。
            </Typography>
          )}
        </List>
      </Box>

      {/* ページネーションもリストの外に配置して常に表示可能にする */}
      {data && data.totalPages > 1 && (
        <PaginationComponent
          totalPages={data.totalPages}
          page={page}
          handlePageChange={handlePageChange}
          isSmallScreen={isSmallScreen}
        />
      )}
    </Box>
  );
};
