import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type SpecifiedCommercialTransactionActDataType = {
  key: string;
  value: string;
};

const specifiedCommercialTransactionActData = [
  {
    key: "販売事業者名",
    value: "株式会社Eco Forest Friendly",
  },
  {
    key: "運営統括責任者",
    value: "代表取締役　芦田 拓弘",
  },
  {
    key: "所在地",
    value: "〒629-0311 京都府南丹市日吉町胡麻丁畑6-1",
  },
  {
    key: "電話番号",
    value: "090-1078-1717",
  },
  {
    key: "お電話対応時間",
    value: "営業時間 9:00-17:00 [定休日：日曜・祝日・年末・年始]",
  },
  {
    key: "メールアドレス",
    value: "ashida.shared@gmail.com",
  },
  {
    key: "お問い合わせ",
    value:
      "お問い合わせは上記メールアドレスへお気軽にお問い合わせください。ashida.shared@gmail.com",
  },
  {
    key: "お支払い方法",
    value: "銀行振込/クレジットカード/コンビニ決済（あと払い手数料）",
  },
  {
    key: "商品代金以外の必要料金",
    value: "・消費税　・銀行振込手数料　・コンビニ（あと払い手数料",
  },
  {
    key: "お支払い時期",
    value: "納品後14日以内にお支払いください。",
  },
  {
    key: "お問い合わせ",
    value:
      "お問い合わせは上記メールアドレスへお気軽にお問い合わせください。ashida.shared@gmail.com",
  },
  {
    key: "商品引き渡し方法",
    value: "注文先会社による配送となります。",
  },
  {
    key: "返品・不良品について",
    value:
      "「不良品・注文先会社の商品の間違い」の場合、当社は負担いたしません。 【交換対象】「商品の間違い」の場合",
  },
  {
    key: "表現、および商品に関する注意書き",
    value:
      "本商品は天然木のため表現や再現性には個人差があり、必ずしも利益や効果を保証したものではございません。",
  },
] as const satisfies ReadonlyArray<SpecifiedCommercialTransactionActDataType>;

const SpecifiedCommercialTransactionAct: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" align="center" gutterBottom>
          特定商取引法に基づく表記
        </Typography>
        {specifiedCommercialTransactionActData.map((data, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant="h6">{data.key}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{data.value}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
};

export default SpecifiedCommercialTransactionAct;
