import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SearchBox } from "../../uis/TextField/SearchBox";
import Avatar from "../../uis/Avatar";
import { Error } from "../../uis/Error";
import { useState } from "react";
import { ReadonlyNotificationResponse } from "../../hooks/useNotificationHook";
import { PaginationComponent } from "../../uis/Pagination";
import { UseQueryResult } from "@tanstack/react-query";
import { SearchTextWIthPaginationParams } from "../../utils/types/general_type";
import { useSearchAndPaginationHook } from "../../hooks/useSearchAndPaginationHook";

interface NotificationListProps {
  useGetNotifications: ({
    searchText,
    page,
    pageSize,
  }: SearchTextWIthPaginationParams) => UseQueryResult<
    ReadonlyNotificationResponse,
    Error
  >;
  isHome?: boolean;
}

export const NotificationList = ({
  useGetNotifications,
  isHome = true,
}: NotificationListProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchClicked, setSearchClicked] = useState(false); // 検索ボックスがクリックされたかを管理

  // カスタムフック
  const { page, searchText, handlePageChange, handleSearchChange } =
    useSearchAndPaginationHook();

  // APIフック（ここで通知データを取得）
  const { data, error: notificationsError } = useGetNotifications({
    searchText,
    page,
  });

  // エラー時の処理
  if (notificationsError) {
    return <Error message={notificationsError?.message} />;
  }

  // 検索ボックスのクリックイベント
  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev);
  };

  return (
    <Box
      sx={{
        width: "90%",
        height: "85vh", // 全体コンテナの高さを固定
        display: "flex",
        flexDirection: "column", // 垂直に配置
        margin: "0 auto", // 横中央寄せ
      }}
    >
      {/* 検索ボックス */}
      <Box sx={{ width: "90%", alignSelf: "center" }}>
        <SearchBox
          isSmallScreen
          placeholder="お知らせを検索(会社名・タイトル)"
          onClick={handleSearchBoxClick}
          onChange={handleSearchChange}
          value={searchText}
          searchClicked={searchClicked}
        />
      </Box>
      {/* 通知リスト部分（スクロール可能領域） */}
      <Box
        sx={{
          flex: 1, // 残りの縦スペースをすべて使用
          width: "100%",
          marginTop: isSmallScreen ? 1 : 2,
          overflowY: "auto", // 縦方向にスクロールバーを表示
        }}
      >
        {data && data.notifications && data.notifications.length > 0 ? (
          data.notifications.map((notification) => (
            <Box
              key={notification.id}
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: isSmallScreen ? 1 : 2,
                padding: isSmallScreen ? 1 : 2,
                border: "1px solid lightgray",
                borderRadius: "8px",
              }}
            >
              {/* 会社アイコン */}
              <Avatar user={notification.user} isSmallScreen={isSmallScreen} />
              {/* 会社名とお知らせ内容 */}
              <Box sx={{ marginLeft: 2 }}>
                <Typography variant={isSmallScreen ? "body1" : "h6"}>
                  {notification.user.company_name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {notification.title}
                </Typography>
              </Box>
            </Box>
          ))
        ) : isHome ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "200px",
              border: "1px dashed lightgray",
              borderRadius: "8px",
              backgroundColor: "#f9f9f9",
              textAlign: "center",
              padding: 2,
            }}
          >
            <Typography variant="h6" color="textPrimary">
              お知らせが存在しません。
            </Typography>
            <Typography variant="body2" color="textSecondary">
              新しいお知らせが追加されるとここに表示されます。
            </Typography>
          </Box>
        ) : null}
      </Box>
      {/* ページネーション（スクロール領域外に配置） */}
      {data && data.totalPages > 1 && (
        <PaginationComponent
          totalPages={data.totalPages}
          page={page}
          handlePageChange={handlePageChange}
          isSmallScreen={isSmallScreen}
        />
      )}
    </Box>
  );
};
