import { Box, Button, Divider, Typography } from "@mui/material";
import { FileDownload } from "./File/FileDownload";
import { LogOrder } from "../hooks/Log/type";
import { WoodOrder } from "../hooks/Wood/type";
import { BlueprintOrder } from "../hooks/Blueprint/type";
import { Wood } from "../hooks/Wood/type";
import { Log } from "../hooks/Log/type";
import { GetBlueprint } from "../hooks/Blueprint/type";
import { Users } from "../hooks/useUserHook";
import { UsersItem } from "../utils/types/item_type";

export function isLogByOrder(
  order?: LogOrder | WoodOrder | BlueprintOrder,
): order is LogOrder {
  return (order as LogOrder)?.log !== undefined;
}

export function isWoodByOrder(
  order?: LogOrder | WoodOrder | BlueprintOrder,
): order is WoodOrder {
  return (order as WoodOrder)?.wood !== undefined;
}

export function isBlueprintByOrder(
  order?: LogOrder | WoodOrder | BlueprintOrder,
): order is BlueprintOrder {
  return (order as BlueprintOrder)?.blueprint !== undefined;
}

export const FileAndDetailsComponent = ({
  order,
  wood,
  log,
  blueprint,
  isShown,
  setOrder,
  users,
  isSmallScreen,
}: {
  order?: LogOrder | WoodOrder | BlueprintOrder;
  wood?: Wood;
  log?: Log;
  blueprint?: GetBlueprint;
  isShown: boolean;
  setOrder: (value: React.SetStateAction<UsersItem | null>) => void;
  users: Users;
  isSmallScreen: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        width: isSmallScreen ? "100%" : "95%",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#e0e0e0",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "4px 8px",
          borderRadius: "4px",
        }}
      >
        <Box>
          {/* 詳細を確認するテキスト */}
          <Typography sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>
            詳細を確認する
          </Typography>
          {/* 詳細確認ボタン */}
          <Button
            variant="contained"
            size="small"
            sx={{
              backgroundColor: "#f4f0f0",
              color: "black",
              border: "1px solid black",
            }}
            onClick={() =>
              setOrder({
                users: users,
                item: {
                  wood,
                  log,
                  blueprint,
                },
              })
            }
          >
            詳細確認
          </Button>
        </Box>
        {/* 「詳細を確認する」ボタンとFileDownload部分をひとまとまりにする。
  グレー背景にして「詳細を確認する」の下に「詳細確認」ボタン、さらにFileDownloadがあれば黒い縦線で区切る。
  全ては右寄せ、上下中央揃えを想定するため、Flexコンテナで対応。 */}
        {isShown && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ mx: 1, borderColor: "black" }}
            />
            <FileDownload
              file={{
                id: isLogByOrder(order)
                  ? order.log_quotation?.id
                  : isWoodByOrder(order)
                    ? order.wood_quotation?.id
                    : isBlueprintByOrder(order)
                      ? order.blueprint_quotation?.id
                      : undefined,
                itemType: log
                  ? "log"
                  : wood
                    ? "wood"
                    : blueprint
                      ? "blueprint"
                      : undefined,
              }}
              category="quotation"
            />
          </>
        )}
      </Box>
    </Box>
  );
};
