import {
  IconButton,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import {
  Controller,
  useFieldArray,
  Control,
  FieldErrors,
} from "react-hook-form";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { BlueprintBase } from "../../../../../hooks/Blueprint/type";
import { ImageForm } from "./image_form";
import { useDownloadImages } from "../../../../../hooks/S3/useS3ImageHook";

interface BlueprintImageFormProps {
  control: Control<BlueprintBase, any>;
  errors: FieldErrors<BlueprintBase>;
  blueprintId: number;
  disabled?: boolean;
}

const BlueprintImageForm: React.FC<BlueprintImageFormProps> = ({
  control,
  errors,
  blueprintId,
  disabled = false,
}) => {
  const {
    fields: imgFields,
    append: imgAppend,
    remove: imgRemove,
  } = useFieldArray({
    control,
    name: "blueprint_images",
  });

  const { mutateAsync: mutateDownloadAsync } = useDownloadImages();

  const handleDownload = async (id: number) => {
    try {
      await mutateDownloadAsync({ id, category: "blueprint" });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ marginBottom: 3 }}>
      <Typography variant="h6">設計図面の画像</Typography>
      <Grid container spacing={2}>
        {imgFields.map((field, index) => (
          <Grid
            container
            spacing={2}
            key={`upload-${field.id}`}
            alignItems="center"
          >
            <Grid item xs={5}>
              <Controller
                name={`blueprint_images.${index}.file` as const}
                control={control}
                disabled={disabled}
                render={({ field: { onChange, value } }) => (
                  <ImageForm
                    selectedFile={value}
                    filePath={field.file_path}
                    handleFileChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        onChange(e.target.files[0]);
                      }
                    }}
                    disabled={true}
                  />
                )}
              />
              {errors.blueprint_images?.[index]?.file && (
                <Typography color="error" variant="body2">
                  {errors.blueprint_images[index]?.file?.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={5}>
              <Controller
                name={`blueprint_images.${index}.description`}
                control={control}
                defaultValue={field.description || ""}
                disabled={disabled}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="画像の説明 (任意)"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            {!disabled && (
              <Grid item xs={2}>
                <IconButton
                  color="secondary"
                  onClick={() => imgRemove(index)}
                  aria-label="remove image"
                >
                  <RemoveCircle />
                </IconButton>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
      {disabled && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ mr: 1 }}
          onClick={() => handleDownload(blueprintId)}
        >
          設計図面をダウンロードする
        </Button>
      )}
      {!disabled && (
        <Box sx={{ marginTop: 2, display: "flex", alignItems: "center" }}>
          <IconButton
            color="primary"
            onClick={() =>
              imgAppend({
                blueprint_id: blueprintId,
                file_path: "",
                description: "",
              })
            }
            aria-label="add image"
          >
            <AddCircle fontSize="large" />
          </IconButton>
          <Typography variant="body2" component="span">
            画像を追加
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default BlueprintImageForm;
