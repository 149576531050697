import { useQuery } from "@tanstack/react-query";
import { PAGE_SIZE, SEARCH_BOX_ITEMS, THIRTY_SECONDS } from "../utils/constant";
import { PaginationParams, SearchIdRequest } from "../utils/types/general_type";
import { useAuth } from "./useAuth";
import config from "../config";
import { handleResponse } from "../utils/response";
import { Woods } from "./Wood/type";
import { Logs } from "./Log/type";
import { GetBlueprints } from "./Blueprint/type";
import { itemKeys } from "../utils/query-key";
import { ItemType } from "../utils/types/item_type";

export interface ReadonlyResponseItems {
  items: Woods | Logs | GetBlueprints;
  totalPages: number;
}

export interface OrderStatusWithSearchParams extends PaginationParams {
  itemName: ItemType;
  isTemporarilyStored: boolean;
  isOrdered: boolean;
}

export function useGetItems({
  itemName,
  isTemporarilyStored,
  isOrdered,
  page,
  pageSize = PAGE_SIZE,
}: OrderStatusWithSearchParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: itemKeys.items_by_filter({
      itemName,
      isTemporarilyStored,
      isOrdered,
      page,
      pageSize,
    }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = {
        is_temporarily_stored: isTemporarilyStored.toString(), // booleanをstringに変換
        is_ordered: isOrdered.toString(), // booleanをstringに変換
        page: page.toString(),
        pageSize: pageSize.toString(),
      } as const;

      // URLSearchParamsでクエリパラメータに変換
      const queryString = new URLSearchParams(params).toString();

      const response = await fetch(
        `${config.backendUrl}/api/${itemName}s?${queryString}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponseItems>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export interface SearchIdRequestWithItemName extends SearchIdRequest {
  // ItemSearchBoxで使用するため、オプショナルにする
  itemName?: ItemType;
}
interface SearchItemsResponse {
  id: number;
  title?: string;
}
// Wood,Log,Blueprintに関するデータを取得する関数
export function useSearchItems({
  searchText,
  limit = SEARCH_BOX_ITEMS,
  id,
  itemName,
}: SearchIdRequestWithItemName) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: itemKeys.search({ searchText, id, itemName }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("search_text", searchText);
      params.append("limit", `${limit}`);
      if (id) {
        params.append("exclude_id", id.toString()); // 現在のIDをリクエストに含める
      }
      const response = await fetch(
        `${config.backendUrl}/api/${itemName}s/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<
        ReadonlyArray<SearchItemsResponse>
      >;
    },
    enabled: !!id,
    staleTime: THIRTY_SECONDS,
  });
}
