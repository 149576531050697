import {
  Box,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";

type SimplifiedFileType = "csv" | "xlsx" | "image";

type AcceptedFileTypesMapping = {
  csv: ".csv";
  xlsx: [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];
  image: "image/*";
};

interface FileUploadProps {
  selectedFile: File | null;
  filePath: string | undefined;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  accept: SimplifiedFileType[]; // 簡略化された形式を指定
  message?: string;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  selectedFile,
  filePath,
  handleFileChange,
  accept,
  message = "ファイルを選択",
}) => {
  const [csvData, setCsvData] = useState<string[][] | null>(null);
  const [excelData, setExcelData] = useState<any[][] | null>(null);

  /**
   * accept で指定された簡略化形式を、実際の input[type=file] 用に変換
   */
  const resolveAcceptedFileTypes = (types: SimplifiedFileType[]): string => {
    const mapping: AcceptedFileTypesMapping = {
      csv: ".csv",
      xlsx: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ],
      image: "image/*",
    };

    return types
      .flatMap((type) => {
        const value = mapping[type];
        return Array.isArray(value) ? value : [value];
      })
      .join(",");
  };

  /**
   * ファイルの種類に応じてプレビューやデータの抽出などを行う
   */
  useEffect(() => {
    if (selectedFile) {
      const fileType = selectedFile.type;

      if (fileType.startsWith("image/")) {
        // 画像の場合
        setCsvData(null);
        setExcelData(null);
      } else if (fileType === "text/csv") {
        // CSVファイルの処理
        const reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target?.result;
          if (typeof text === "string") {
            const rows = text.split("\n").map((row) => row.split(","));
            setCsvData(rows);
            setExcelData(null);
          }
        };
        reader.readAsText(selectedFile);
      } else if (
        fileType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType === "application/vnd.ms-excel"
      ) {
        // Excelファイルの処理
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target?.result;
          if (data) {
            const workbook = XLSX.read(data, { type: "binary" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const json: any[][] = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
            });
            setExcelData(json);
            setCsvData(null);
          }
        };
        reader.readAsBinaryString(selectedFile);
      } else {
        // その他のファイルタイプ
        setCsvData(null);
        setExcelData(null);
      }
    } else {
      // ファイルが選択されていない場合はリセット
      setCsvData(null);
      setExcelData(null);
    }
  }, [selectedFile]);

  /**
   * プレビュー部分の描画を行う関数
   */
  const renderPreview = () => {
    // ▼ 1. ファイル未選択 && filePathが存在する場合
    if (!selectedFile && filePath) {
      if (accept.includes("image")) {
        // 画像ファイルの場合は画像を表示
        return (
          <Box
            component="img"
            src={filePath}
            alt="Selected"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
        );
      } else {
        // 画像でない場合はファイル名を表示
        const fileName = filePath.split(/[\\/]/).pop() || "ファイル名不明";
        return (
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "#f7f7f7",
              borderRadius: "10px",
              padding: 2,
            }}
          >
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              アップロード済みファイル
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {fileName}
            </Typography>
          </Box>
        );
      }
    }
    // ▼ 2. ファイルが選択されている場合
    else if (selectedFile) {
      const fileType = selectedFile.type;

      // (a) 画像ファイルの場合はプレビュー表示
      if (fileType.startsWith("image/")) {
        return (
          <Box
            component="img"
            src={URL.createObjectURL(selectedFile)}
            alt="Selected"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
        );
      }
      // (b) CSVファイルのプレビュー
      else if (fileType === "text/csv" && csvData) {
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              overflow: "auto",
              padding: 2,
              backgroundColor: "#fff",
              borderRadius: "10px",
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  {csvData[0].map((header, index) => (
                    <TableCell key={index}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {csvData.slice(1).map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>{cell}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        );
      }
      // (c) Excelファイルのプレビュー
      else if (
        (fileType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          fileType === "application/vnd.ms-excel") &&
        excelData
      ) {
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              overflow: "auto",
              padding: 2,
              backgroundColor: "#fff",
              borderRadius: "10px",
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  {excelData[0].map((header, index) => (
                    <TableCell key={index}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {excelData.slice(1).map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>{cell}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        );
      }
      // (d) その他のファイルタイプ
      else {
        return (
          <Typography variant="body1" color="textSecondary">
            プレビューできないファイルタイプです。
          </Typography>
        );
      }
    }
    // ▼ 3. ファイルが選択されていない && filePathもない場合
    else {
      return (
        <Box sx={{ textAlign: "center" }}>
          <IconButton color="primary" component="label">
            <PhotoCamera sx={{ fontSize: 50 }} />
            <input
              hidden
              accept={resolveAcceptedFileTypes(accept)}
              type="file"
              onChange={handleFileChange}
            />
          </IconButton>
          <Typography>{message}</Typography>
        </Box>
      );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 3,
        marginBottom: 3,
        position: "relative",
        backgroundColor: "#cacaca",
        borderRadius: "10px",
      }}
    >
      {/* 
        背景クリックでもファイル選択ダイアログを開けるようにするための 
        隠し input[file] を持つ IconButton を敷いている
      */}
      <IconButton
        color="primary"
        component="label"
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 1,
        }}
      >
        <input
          hidden
          accept={resolveAcceptedFileTypes(accept)}
          type="file"
          onChange={handleFileChange}
        />
      </IconButton>
      {renderPreview()}
    </Box>
  );
};
