import { z } from "zod";

export type SignInType = z.infer<typeof SignInSchema>;
export const SignInSchema = z.object({
  email: z
    .string()
    .email({ message: "メールアドレスの形式が正しくありません" }),
  password: z
    .string()
    .min(8, { message: "パスワードは8文字以上に設定してください" })
    .max(30, { message: "パスワードは30文字以下に設定してください" })
    .regex(/\d+/, { message: "パスワードは半角英数字混合で入力してください" })
    .regex(/[A-Za-z]+/, {
      message: "パスワードは半角英数字混合で入力してください",
    }),
});

export type PasswordResetType = z.infer<typeof PasswordResetSchema>;
export const PasswordResetSchema = z.object({
  email: z
    .string()
    .email({ message: "メールアドレスの形式が正しくありません" }),
});

export type PasswordResetConfirmType = z.infer<
  typeof PasswordResetConfirmSchema
>;
export const PasswordResetConfirmSchema = z.object({
  email: z
    .string()
    .email({ message: "メールアドレスの形式が正しくありません" }),
  newPassword: z
    .string()
    .min(8, { message: "パスワードは8文字以上に設定してください" })
    .max(30, { message: "パスワードは30文字以下に設定してください" })
    .regex(/\d+/, { message: "パスワードは半角英数字混合で入力してください" })
    .regex(/[A-Za-z]+/, {
      message: "パスワードは半角英数字混合で入力してください",
    }),
  confirmationCode: z
    .string()
    .length(6, { message: "確認コードは6桁の数字である必要があります。" })
    .regex(/^\d{6}$/, {
      message: "確認コードは6桁の数字のみを入力してください。",
    }),
});

export type InquiryType = z.infer<typeof InquirySchema>;
export const InquirySchema = z.object({
  email: z
    .string()
    .email({ message: "メールアドレスの形式が正しくありません" }),
  companyName: z.string().min(1, { message: "会社名を入力してください" }), // 会社名のフィールドを追加
  content: z.string().min(1, { message: "問い合わせ内容を入力してください" }),
});

export type UpdateUserType = z.infer<typeof UpdateUserSchema>;
export const UpdateUserSchema = z.object({
  industry: z.number({
    message: "業種を選択してください",
    required_error: "業種を選択してください",
  }),
  companyName: z.string().min(1, { message: "会社名を入力してください" }), // 会社名のフィールドを追加
  phoneNumber: z
    .string()
    .min(8, { message: "電話番号は8桁以上で入力してください" }) // 担当者電話番号のフィールドを追加
    .max(15, { message: "電話番号は15桁以下に設定してください" })
    .regex(/^[0-9\-+]+$/, { message: "有効な電話番号を入力してください" }) // 電話番号の形式を定義
    .refine((value) => !value.includes("-"), {
      message: "電話番号にハイフンを含めないでください",
    }),
  companyImage: z
    .string()
    .min(1, { message: "画像を入力してください" })
    // .optional(), // companyImageをオプションに変更
    .nullable(), // null値も許可
  corporateNumber: z
    .string()
    .length(13, { message: "法人番号は13桁で入力してください" })
    .regex(/^\d+$/, { message: "法人番号は数字のみで入力してください" })
    .optional()
    .or(z.literal("")), // 空文字も許容
  // 郵便番号（形式: 123-4567 または 1234567）
  postalCode: z
    .string()
    .length(8, { message: "郵便番号は8桁で入力してください（ハイフンを含む）" })
    .regex(/^\d{3}-\d{4}$/, {
      message: "有効な郵便番号を入力してください（例: 123-4567）",
    }),
  // 住所
  address: z.string().min(1, { message: "住所を入力してください" }),
  isStripeAccountCreated: z.boolean(),
});

export type CreateUser = z.infer<typeof CreateUserSchema>;
export const CreateUserSchema = z.object({
  email: z
    .string()
    .email({ message: "メールアドレスの形式が正しくありません" }),
  admin_password: z
    .string()
    .min(8, { message: "パスワードは8文字以上に設定してください" })
    .max(30, { message: "パスワードは30文字以下に設定してください" })
    .regex(/[a-z]+/, {
      message: "パスワードには少なくとも1つの小文字を含めてください",
    }) // 小文字
    .regex(/[A-Z]+/, {
      message: "パスワードには少なくとも1つの大文字を含めてください",
    }) // 大文字
    .regex(/\d+/, {
      message: "パスワードには少なくとも1つの数字を含めてください",
    }) // 数字
    .regex(/[\W_]+/, {
      message: "パスワードには少なくとも1つの特殊文字を含めてください",
    }), // 特殊文字
  is_admin: z.boolean(), // 管理者権限のチェックボックス
});

export type Password = z.infer<typeof PasswordSchema>;
export const PasswordSchema = z.object({
  adminPassword: z
    .string()
    .min(8, { message: "パスワードは8文字以上に設定してください" })
    .max(30, { message: "パスワードは30文字以下に設定してください" })
    .regex(/[a-z]+/, {
      message: "パスワードには少なくとも1つの小文字を含めてください",
    }) // 小文字
    .regex(/[A-Z]+/, {
      message: "パスワードには少なくとも1つの大文字を含めてください",
    }) // 大文字
    .regex(/\d+/, {
      message: "パスワードには少なくとも1つの数字を含めてください",
    }) // 数字
    .regex(/[\W_]+/, {
      message: "パスワードには少なくとも1つの特殊文字を含めてください",
    }), // 特殊文字
});

export type SearchTransactionAmount = z.infer<
  typeof SearchTransactionAmountSchema
>;
export const SearchTransactionAmountSchema = z.object({
  startDate: z.string().regex(/^\d{4}-(0[1-9]|1[0-2])$/, {
    message: "startDate must be in the format YYYY-MM (e.g., 2024-03)",
  }),
  endDate: z.string().regex(/^\d{4}-(0[1-9]|1[0-2])$/, {
    message: "endDate must be in the format YYYY-MM (e.g., 1999-12)",
  }),
  type: z.enum(["order", "receive", "both"]),
});
