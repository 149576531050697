import { Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { BlackButton, WhiteButton } from "../../../../uis/Button/Button";
import { useForm, useWatch } from "react-hook-form";
import {
  useGetBlueprint,
  useUpdateBlueprint,
} from "../../../../hooks/Blueprint/useBlueprintHook";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../../uis/Loading";
import { Error } from "../../../../uis/Error";
import { ItemSearchBox } from "../../../../uis/Item/ItemSearchBox";
import { BlueprintBase } from "../../../../hooks/Blueprint/type";
import BlueprintImageForm from "./BlueprintImageForm";
import { Title } from "../../../../uis/Title";
import { useSearchItems } from "../../../../hooks/useItemHook";

export const CreateBlueprintList = () => {
  const { mutateAsync: mutateUpdateBlueprint } = useUpdateBlueprint();
  const { blueprint_id } = useParams<{ blueprint_id: string }>();
  const { data, error, isPending } = useGetBlueprint({
    id: Number(blueprint_id),
    isOrdered: false,
    isTemporarilyStored: true,
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<BlueprintBase>({
    defaultValues: {
      blueprint_images: [],
    },
  });
  const navigate = useNavigate();

  // dataの変更時にフォームの値を更新
  useEffect(() => {
    if (data) {
      setValue("title", data.title);
      if (data.blueprint_images && data.blueprint_images.length > 0) {
        setValue("blueprint_images", data.blueprint_images);
      } else {
        setValue("blueprint_images", []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSaveTemporarily = async (formData: BlueprintBase) => {
    formData.id = Number(blueprint_id);
    formData.is_temporarily_stored = true;
    formData.is_ordered = false;
    try {
      await mutateUpdateBlueprint(formData);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (formData: BlueprintBase) => {
    formData.id = Number(blueprint_id);
    try {
      await mutateUpdateBlueprint(formData);
      navigate(`/order/mailing/blueprint/${blueprint_id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const watchedTitle = useWatch({ control, name: "title" });
  const watchedBlueprintImages = useWatch({
    control,
    name: "blueprint_images",
  });

  const hasAtLeastOneImage =
    watchedBlueprintImages &&
    watchedBlueprintImages.length > 0 &&
    watchedBlueprintImages.some((img) => img.file || img.file_path);

  const isWhiteButtonDisabled = !watchedTitle && !hasAtLeastOneImage;

  const isBlackButtonDisabled = !watchedTitle || !hasAtLeastOneImage;

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <Title title="設計図送信" />
      <ItemSearchBox
        useSearchItems={useSearchItems}
        placeholder="一時保存したリスト一覧"
        navigateTo={(id) => navigate(`/order/create_blueprint_list/${id}`)}
        id={Number(blueprint_id)}
        itemName="blueprint"
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%", marginTop: "10%" }}
      >
        <TextField
          placeholder="タイトルを入力"
          variant="outlined"
          fullWidth
          {...register("title")}
          error={!!errors.title}
          helperText={errors.title ? errors.title.message : null}
          sx={{
            border: "2px solid black",
            marginBottom: 3,
          }}
        />
        <BlueprintImageForm
          control={control}
          errors={errors}
          blueprintId={Number(blueprint_id)}
        />
        <WhiteButton
          isSmallScreen
          text={"作成内容を一時保存"}
          onClick={handleSubmit(handleSaveTemporarily)}
          disabled={isWhiteButtonDisabled || isSubmitting}
        />
        <BlackButton
          isSmallScreen
          text="送付先選択へ"
          type="submit"
          disabled={isBlackButtonDisabled || isSubmitting}
        />
      </form>
    </Box>
  );
};
