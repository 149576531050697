import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { BlueprintDetail } from "../hooks/Blueprint/type";
import { LogDetail } from "../hooks/Log/type";
import { WoodDetail } from "../hooks/Wood/type";
import { Item } from "../utils/types/item_type";
import { BlueprintImageComponent } from "./BlueprintImage";

// 共通の詳細表示コンポーネントをジェネリックに修正
const RenderDetails = <T extends LogDetail | WoodDetail | BlueprintDetail>({
  title,
  totalAmount,
  details,
  detailFields,
  renderImages,
}: {
  title: string;
  totalAmount?: number;
  details: T[];
  detailFields: Partial<Record<keyof T, string>>;
  renderImages?: () => React.ReactNode;
}) => (
  <>
    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
      タイトル: {title || "N/A"}
    </Typography>
    {totalAmount !== undefined && totalAmount !== null && totalAmount !== 0 && (
      <Typography variant="subtitle1" color="textSecondary" sx={{ mt: 1 }}>
        見積もり金額（合計）: ¥{totalAmount.toLocaleString()}
      </Typography>
    )}
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            {(Object.keys(detailFields) as Array<keyof T>).map((field) => (
              <TableCell key={String(field)}>{detailFields[field]}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {details.map((detail, index) => (
            <TableRow key={index}>
              {(Object.keys(detailFields) as Array<keyof T>).map((field) => (
                <TableCell
                  key={String(field)}
                  align={
                    field === "length" ||
                    field === "unit_volume" ||
                    field === "quantity" ||
                    field === "total_volume" ||
                    field === "amount"
                      ? "right"
                      : "left"
                  }
                >
                  {detail[field] !== undefined
                    ? (
                        detail[field] as unknown as {
                          toLocaleString: () => string;
                        }
                      )?.toLocaleString()
                    : "N/A"}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {renderImages && renderImages()}
  </>
);

export const ItemsRenderDetails = ({ wood, log, blueprint }: Item) => {
  return (
    <>
      {/* Woodの詳細表示 */}
      {wood && (
        <RenderDetails
          title={wood.title || "N/A"}
          totalAmount={wood.total_amount}
          details={wood.wood_details}
          detailFields={{
            name: "名称",
            species: "樹種",
            material: "素材",
            origin: "産地",
            grade: "等級",
            width: "幅",
            height: "高さ",
            length: "長さ",
            unit_volume: "単材積",
            quantity: "本数",
            total_volume: "材積",
            amount: "金額",
          }}
        />
      )}

      {/* Logの詳細表示 */}
      {log && (
        <RenderDetails
          title={log.title || "N/A"}
          totalAmount={log.total_amount}
          details={log.log_details}
          detailFields={{
            species: "樹種",
            material: "素材",
            origin: "産地",
            grade: "等級",
            top_diameter: "末口径",
            length: "長さ",
            unit_volume: "単材積",
            quantity: "本数",
            total_volume: "材積",
            amount: "金額",
          }}
        />
      )}

      {/* Blueprintの詳細表示 */}
      {blueprint && (
        <RenderDetails
          title={blueprint.title || "N/A"}
          totalAmount={blueprint.total_amount}
          details={blueprint.blueprint_details}
          detailFields={{
            name: "名称",
            species: "樹種",
            material: "素材",
            origin: "産地",
            grade: "等級",
            width: "幅",
            height: "高さ",
            length: "長さ",
            unit_volume: "単材積",
            quantity: "本数",
            total_volume: "材積",
            amount: "金額",
          }}
          renderImages={() =>
            blueprint.blueprint_images.map((it, idx) => (
              <BlueprintImageComponent key={idx} blueprintImage={it} />
            ))
          }
        />
      )}
    </>
  );
};
