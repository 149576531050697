import { useGetWood } from "../../../hooks/Wood/useWoodHook";
import { useSearchWoodUsers } from "../../../hooks/useUserHook";
import { MailingComponent } from "../../common/MailingComponent";

export const WoodMailing = () => {
  return (
    <MailingComponent
      idParamName="wood_id"
      useGetData={useGetWood}
      useSearchUsers={useSearchWoodUsers}
      itemName="wood"
    />
  );
};
