import { Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { BlackButton, WhiteButton } from "../../../../uis/Button/Button";
import { useForm, useWatch, useFieldArray } from "react-hook-form";
import {
  useGetBlueprintByReceiver,
  useUpdateBlueprintByReceiver,
} from "../../../../hooks/Blueprint/useBlueprintHook";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../../uis/Loading";
import { Error } from "../../../../uis/Error";
import { BlueprintTable } from "./blueprint_table";
import { ItemSearchBox } from "../../../../uis/Item/ItemSearchBox";
import { FileUpload } from "../../../../uis/File/FileUpload";
import { BlueprintBase } from "../../../../hooks/Blueprint/type";
import BlueprintImageForm from "../CreateBlueprintList/BlueprintImageForm";
import { useCreateItemListHook } from "../../../../hooks/useCreateItemListHook";
import { useSearchItems } from "../../../../hooks/useItemHook";

export const CreateBlueprintDetailList = () => {
  const { mutateAsync } = useUpdateBlueprintByReceiver();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const {
    data: fetchedData,
    error,
    isPending,
    refetch,
  } = useGetBlueprintByReceiver(Number(id));

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<BlueprintBase>({
    defaultValues: {
      blueprint_images: [],
    },
  });
  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: "blueprint_details",
  });

  const watchedBlueprintDetails = useWatch({
    control,
    name: "blueprint_details",
  });

  const {
    data,
    selectedFile,
    isMutatePending,
    areAllDetailsComplete,
    isPolling,
    onSubmit,
    handleFileChange,
    handleFileUpload,
  } = useCreateItemListHook({
    id: Number(id),
    itemName: "blueprint",
    fetchedData,
    watchedDetails: watchedBlueprintDetails,
    refetch,
    mutateAsync,
    navigate,
  });

  // dataの変更時にフォームの値を更新
  // blueprint_imagesをセットするため、useCreateItemListHookとは独自に実装。
  useEffect(() => {
    if (data) {
      setValue("title", data.title);
      if (data.blueprint_details && data.blueprint_details.length > 0) {
        replace(data.blueprint_details);
      } else {
        replace([]);
      }
      if (data.blueprint_images && data.blueprint_images.length > 0) {
        setValue("blueprint_images", data.blueprint_images);
      } else {
        setValue("blueprint_images", []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <ItemSearchBox
        placeholder="一時保存したリスト一覧"
        navigateTo={(id) => navigate(`/order/create_blueprint_list/${id}`)}
        useSearchItems={useSearchItems}
        id={Number(id)}
        itemName="blueprint"
      />
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        {isPolling ? (
          "アップロード中...."
        ) : (
          <FileUpload
            selectedFile={selectedFile}
            filePath={data?.file_path}
            handleFileChange={handleFileChange}
            accept={["csv", "xlsx"]}
            message="CSV/Excelファイルを取りこむ"
          />
        )}
        <TextField
          placeholder="タイトルを入力"
          variant="outlined"
          fullWidth
          {...register("title")}
          error={!!errors.title}
          helperText={errors.title ? errors.title.message : null}
          sx={{
            border: "2px solid black",
            marginBottom: 3,
          }}
          disabled={true}
        />
        <BlueprintTable
          register={register}
          errors={errors}
          fields={fields}
          append={append}
          remove={remove}
        />
        <BlueprintImageForm
          control={control}
          errors={errors}
          blueprintId={Number(id)}
          disabled={true}
        />
        <WhiteButton
          isSmallScreen
          text="CSV/Excelファイルをアップロードする"
          onClick={handleFileUpload}
          disabled={!selectedFile || isMutatePending}
        />
        <BlackButton
          isSmallScreen
          text={"更新する"}
          type="submit"
          disabled={!areAllDetailsComplete || isSubmitting}
        />
      </form>
    </Box>
  );
};
