import React, { useState } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import { Error } from "../../uis/Error";
import { SearchBox } from "../../uis/TextField/SearchBox";
import Avatar from "../../uis/Avatar";
import { OrderDetailModal } from "../../uis/Modals/OrderDetailModal";
import { ItemType, UsersItem } from "../../utils/types/item_type";
import { toast } from "react-toastify";
import { isBlueprint, isLog, isWood } from "./MailingComponent";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { LogOrder } from "../../hooks/Log/type";
import { WoodOrder } from "../../hooks/Wood/type";
import { BlueprintOrder } from "../../hooks/Blueprint/type";
import { Color } from "../../utils/color";
import { FileAndDetailsComponent } from "../../uis/FileAndDetailsComponent";
import { extractOrders } from "../../utils/extract";
import { Title } from "../../uis/Title";
import { conversionItemName } from "../../utils/conversion";
import {
  useApplyForOrderAgain,
  useContractOrder,
  useSearchOrderStatuses,
} from "../../hooks/useOrderHook";
import { PaginationComponent } from "../../uis/Pagination";
import { useSearchAndPaginationHook } from "../../hooks/useSearchAndPaginationHook";

interface OrderComponentProps {
  itemName: ItemType;
}

/**
 * 注文ステータスを色分けするための関数
 */
const getOrderStatusStyle = (order: LogOrder | WoodOrder | BlueprintOrder) => {
  // 共通のCSSスタイルを定義
  const baseSx = {
    padding: "4px 8px",
    borderRadius: "4px",
    display: "inline-block",
  } as const;

  // 状態に応じたCSSを追加
  if (order.is_received) {
    // 承諾
    return {
      ...baseSx,
      backgroundColor: "red",
      color: "white",
    };
  } else if (order.is_pending) {
    // 保留中
    return {
      ...baseSx,
      backgroundColor: "white",
      color: "red",
      border: "1px solid red",
    };
  } else if (order.is_denied) {
    // 辞退
    return {
      ...baseSx,
      backgroundColor: "gray",
      color: "white",
    };
  } else {
    // 申請中
    return {
      ...baseSx,
      backgroundColor: "white",
      color: "gray",
      border: "1px solid gray",
    };
  }
};

/**
 * 状態ごとのボタンを返す関数
 */
const getOrderActionButton = (
  order: LogOrder | WoodOrder | BlueprintOrder,
  itemId: number | undefined,
  handleApplyForOrderAgain: (
    orderId: number,
    isContract: boolean,
    itemId?: number,
  ) => Promise<void> | void,
  isPending: boolean,
  isContractPending: boolean,
) => {
  // デフォルトは「承諾」→「発注する」
  let buttonText = "発注する";
  let buttonStyle = {
    backgroundColor: "red",
    color: "white",
    marginRight: "8px",
  };
  let isContract = true;
  let isDisabled = false;

  // 2度注文を保留・辞退したユーザー
  if (
    (order.is_denied_sended || order.is_pending_sended) &&
    !order.is_received
  ) {
    buttonText = "再度保留・辞退しました";
    buttonStyle = {
      backgroundColor: "black",
      color: "white",
      marginRight: "8px",
    };
    isContract = false;
    isDisabled = true;
  } else if (order.is_pending || order.is_denied) {
    // 保留・辞退 -> 「再度注文する」
    buttonText = "再度注文する";
    buttonStyle = {
      backgroundColor: "black",
      color: "white",
      marginRight: "8px",
    };
    isContract = false;
    isDisabled = false;
  } else if (!order.is_received) {
    // 申請中 -> 「契約する」ボタン
    // （仕様で「承諾の場合は発注ボタン」「申請中の場合は契約ボタン」など調整可）
    buttonText = "契約する";
    buttonStyle = {
      backgroundColor: Color.Main,
      color: "white",
      marginRight: "8px",
    };
    isContract = true;
  }

  return (
    <Button
      variant="contained"
      size="small"
      sx={buttonStyle}
      onClick={() => handleApplyForOrderAgain(order.id, isContract, itemId)}
      disabled={isPending || isContractPending || isDisabled}
    >
      {buttonText}
    </Button>
  );
};

/**
 * メインコンポーネント
 */
export const OrderComponent = ({ itemName }: OrderComponentProps) => {
  // カスタムフック
  const {
    page,
    searchText,
    statuses,
    handlePageChange,
    handleSearchChange,
    handleClick,
  } = useSearchAndPaginationHook();

  const [searchClicked, setSearchClicked] = useState(false);
  const [order, setOrder] = useState<UsersItem | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { data, error } = useSearchOrderStatuses({
    type: itemName,
    searchText,
    statuses,
    page,
  });
  const { mutateAsync: mutateApplyAgainAsync, isPending } =
    useApplyForOrderAgain();
  const { mutateAsync: mutateContractAsync, isPending: isContractPending } =
    useContractOrder();

  // 開閉状態を管理
  const [openItems, setOpenItems] = useState<{ [key: number]: boolean }>({});

  // タイトル行の開閉ボタン
  const handleToggle = (id: number) => {
    setOpenItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  // 検索ボックスのクリックハンドラ
  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev);
  };

  // 再注文 or 契約等のハンドラ
  const handleApplyForOrderAgain = async (
    orderId: number,
    isContract: boolean,
    itemId?: number,
  ) => {
    if (!itemId) {
      toast.error("エラーが発生しました");
      return;
    }
    try {
      if (isContract) {
        await mutateContractAsync({ type: itemName, orderId, itemId });
      } else {
        await mutateApplyAgainAsync({ type: itemName, orderId });
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <Box
      sx={{
        // 全体コンテナを縦に並べる
        width: "55%",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        // 画面の高さをある程度固定し、スクロールをつける
        height: "90vh", // 必要に応じて調整
      }}
    >
      {/* タイトル */}
      <Title title={`${conversionItemName(itemName)}リスト　注文申請中`} />
      {/* 検索ボックス */}
      <SearchBox
        placeholder="タイトルを検索"
        isSmallScreen={isSmallScreen}
        onClick={handleSearchBoxClick}
        onChange={handleSearchChange}
        value={searchText}
        searchClicked={searchClicked}
      />
      {/* ステータスフィルタ */}
      <Box sx={{ mb: 2 }}>
        <Button onClick={() => handleClick("applying")}>
          {statuses.includes("applying") ? "申請中を取り消す" : "申請中"}
        </Button>
        <Button onClick={() => handleClick("receiving")}>
          {statuses.includes("receiving") ? "承諾を取り消す" : "承諾"}
        </Button>
        <Button onClick={() => handleClick("pending")}>
          {statuses.includes("pending") ? "保留を取り消す" : "保留"}
        </Button>
        <Button onClick={() => handleClick("denying")}>
          {statuses.includes("denying") ? "辞退を取り消す" : "辞退"}
        </Button>
      </Box>

      {/* リスト部分をスクロール領域に */}
      <Box
        sx={{
          flex: 1, // 残りの縦スペースを全て使用
          overflowY: "auto", // 縦スクロールを付ける
        }}
      >
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {data &&
            data.items.length > 0 &&
            data.items.map((item) => {
              const log = isLog(item) ? item : undefined;
              const wood = isWood(item) ? item : undefined;
              const blueprint = isBlueprint(item) ? item : undefined;

              const orders = extractOrders({ wood, log, blueprint });
              const totalAmount = item?.total_amount;
              const open = openItems[item?.id || 0] || false;

              return (
                <List
                  key={item.id}
                  sx={{
                    border: "1px solid gray",
                    borderRadius: "8px",
                    mb: 2,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  {/* タイトル行 */}
                  <ListItem
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "8px",
                      borderBottomRightRadius: open ? 0 : "8px",
                      borderBottomLeftRadius: open ? 0 : "8px",
                      background: open ? Color.Main : "#f0f0f0",
                      mb: open && orders && orders.length > 0 ? 2 : 0,
                    }}
                  >
                    {/* タイトル */}
                    <Box
                      sx={{
                        color: open ? "white" : "black",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        fontWeight: "bold",
                      }}
                    >
                      {item?.title || "タイトルが存在しません"}
                    </Box>

                    {/* 開閉ボタン */}
                    <IconButton onClick={() => handleToggle(item.id)}>
                      {open ? (
                        <ExpandLess sx={{ color: open ? "white" : "black" }} />
                      ) : (
                        <ExpandMore sx={{ color: open ? "white" : "black" }} />
                      )}
                    </IconButton>
                  </ListItem>

                  {/* 開いている場合のみ、内部の注文情報を表示 */}
                  {open && orders && orders.length > 0 && (
                    <Box sx={{ pl: 1, pr: 1 }}>
                      {orders.map((orderItem) => (
                        <ListItem
                          key={orderItem.id}
                          alignItems="center"
                          sx={{
                            display: "flex",
                            flexDirection: isSmallScreen ? "column" : "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 2,
                          }}
                        >
                          {/* ステータス表示 */}
                          <Box sx={{ width: "10%" }}>
                            <Typography sx={getOrderStatusStyle(orderItem)}>
                              {orderItem.is_received
                                ? "承諾"
                                : orderItem.is_pending
                                  ? "保留中"
                                  : orderItem.is_denied
                                    ? "辞退"
                                    : "申請中"}
                            </Typography>
                          </Box>

                          {/* ユーザー名 & 金額 & 再注文ボタン */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              width: isSmallScreen ? "100%" : "45%",
                              mb: isSmallScreen ? 1 : 0,
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                user={orderItem.user}
                                isSmallScreen={isSmallScreen}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={orderItem.user.company_name}
                              secondary={
                                <Box>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    見積もり金額（合計）: ¥
                                    {totalAmount
                                      ? totalAmount.toLocaleString()
                                      : "データが存在しません"}
                                  </Typography>
                                  {/* 発注/契約/再度注文ボタン */}
                                  {orderItem.is_received ||
                                  orderItem.is_pending ||
                                  orderItem.is_denied
                                    ? getOrderActionButton(
                                        orderItem,
                                        item.id,
                                        handleApplyForOrderAgain,
                                        isPending,
                                        isContractPending,
                                      )
                                    : null}
                                </Box>
                              }
                              sx={{ ml: 2 }}
                            />
                          </Box>

                          {/* 詳細・ファイルダウンロードセクション（右側） */}

                          <FileAndDetailsComponent
                            order={orderItem}
                            wood={wood}
                            log={log}
                            blueprint={blueprint}
                            setOrder={setOrder}
                            isShown={
                              !orderItem.is_pending &&
                              !orderItem.is_denied &&
                              orderItem.is_received
                            }
                            users={[orderItem.user]}
                            isSmallScreen={isSmallScreen}
                          />
                        </ListItem>
                      ))}
                    </Box>
                  )}
                </List>
              );
            })}
        </List>
      </Box>

      {/* ページネーション（スクロール領域の外に配置） */}
      {data && data.totalPages > 1 && (
        <PaginationComponent
          totalPages={data.totalPages}
          page={page}
          handlePageChange={handlePageChange}
          isSmallScreen={isSmallScreen}
        />
      )}

      {/* 詳細モーダル */}
      {order !== null && (
        <OrderDetailModal
          userItem={order}
          open={order !== null}
          onClose={() => setOrder(null)}
        />
      )}
    </Box>
  );
};
