import { useGetLog } from "../../../hooks/Log/useLogHook";
import { useSearchLogUsers } from "../../../hooks/useUserHook";
import { MailingComponent } from "../../common/MailingComponent";

export const LogMailing = () => {
  return (
    <MailingComponent
      idParamName="log_id"
      useGetData={useGetLog}
      useSearchUsers={useSearchLogUsers}
      itemName="log"
    />
  );
};
