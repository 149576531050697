import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { OrderStatusWithApplying } from "../utils/types/general_type";

/**
 * カスタムフック用のオプション
 * - pageParam: クエリパラメータのキー(デフォルト: "page")
 * - searchParam: クエリパラメータのキー(デフォルト: "search")
 * - defaultPage: ページ番号のデフォルト値(デフォルト: 1)
 * - defaultSearchText: 検索テキストのデフォルト値(デフォルト: "")
 */
interface UseSearchAndPaginationOptions {
  pageParam?: string;
  searchParam?: string;
  defaultPage?: number;
  defaultSearchText?: string;
  statusParam?: "statuses";
  defaultStatuses?: "";
}

/**
 * 戻り値
 * - page, setPage
 * - searchText, setSearchText
 * - handlePageChange: MUIのPaginationが呼び出すページ変更ハンドラ
 * - handleSearchChange: 検索テキスト変更ハンドラ
 * - searchParams, setSearchParams: React Router v6のuseSearchParams
 */
interface UseSearchAndPaginationReturn {
  page: number;
  searchText: string;
  statuses: OrderStatusWithApplying[];
  searchParams: URLSearchParams;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClick: (type: OrderStatusWithApplying) => void;
}

/**
 * ページネーションと検索テキストを共通管理するカスタムフック
 */
export function useSearchAndPaginationHook(
  options?: UseSearchAndPaginationOptions,
): UseSearchAndPaginationReturn {
  const {
    pageParam = "page",
    searchParam = "search",
    defaultPage = 1,
    defaultSearchText = "",
    statusParam = "statuses",
    defaultStatuses = "",
  } = options || {};

  const [searchParams, setSearchParams] = useSearchParams();

  // クエリパラメータから初期値を取得
  const initPage = parseInt(
    searchParams.get(pageParam) || defaultPage.toString(),
    10,
  );
  const initSearchText = searchParams.get(searchParam) || defaultSearchText;
  const initialStatuses = searchParams.get(statusParam) || defaultStatuses;

  // state 管理
  const [page, setPage] = useState<number>(
    isNaN(initPage) || initPage < 1 ? defaultPage : initPage,
  );
  const [searchText, setSearchText] = useState<string>(initSearchText);
  const [statuses, setStatuses] = useState<OrderStatusWithApplying[]>(
    initialStatuses
      ? (initialStatuses.split(",") as OrderStatusWithApplying[])
      : [],
  );

  /**
   * ページ変更時に呼び出すハンドラ
   * MUIのPaginationコンポーネントなどで使用
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
    setSearchParams({
      ...Object.fromEntries(searchParams),
      [pageParam]: value.toString(),
    });
  };

  /**
   * 検索テキスト変更時に呼び出すハンドラ
   * (SearchBoxなどのonChangeで使用)
   */
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);
    setPage(1); // 検索テキストが変更されたらページをリセット

    // クエリパラメータを更新: search を新しい検索テキストに、page を1にリセット
    setSearchParams({
      ...Object.fromEntries(searchParams),
      [searchParam]: newSearchText,
      [pageParam]: defaultPage.toString(), // 1など
    });
  };

  const handleClick = (type: OrderStatusWithApplying) => {
    setStatuses((prevStatuses) => {
      let updatedStatuses: OrderStatusWithApplying[];
      if (prevStatuses.includes(type)) {
        updatedStatuses = prevStatuses.filter((status) => status !== type);
      } else {
        updatedStatuses = [...prevStatuses, type];
      }

      // クエリパラメータを更新
      setSearchParams((prev) => {
        const params = new URLSearchParams(prev);
        if (updatedStatuses.length > 0) {
          params.set("statuses", updatedStatuses.join(","));
        } else {
          params.delete("statuses");
        }
        return {
          ...Object.fromEntries(params),
          page: "1",
        };
      });

      return updatedStatuses;
    });
  };
  /**
   * searchParamsが変わったときに、pageとsearchTextを更新する
   */
  useEffect(() => {
    const currentPage = parseInt(
      searchParams.get(pageParam) || defaultPage.toString(),
      10,
    );
    const currentSearchText =
      searchParams.get(searchParam) || defaultSearchText;
    const currentStatusesParam =
      searchParams.get(statusParam) || defaultStatuses;

    // ページ
    if (!isNaN(currentPage) && currentPage !== page) {
      setPage(currentPage);
    }
    // 検索
    if (currentSearchText !== searchText) {
      setSearchText(currentSearchText);
    }

    if (currentStatusesParam) {
      setStatuses(currentStatusesParam.split(",") as OrderStatusWithApplying[]);
    } else {
      setStatuses([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return {
    page,
    searchText,
    statuses,
    searchParams,
    handlePageChange,
    handleSearchChange,
    handleClick,
  };
}
