import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import {
  PasswordResetSchema,
  PasswordResetType,
  PasswordResetConfirmSchema,
  PasswordResetConfirmType,
} from "../../utils/schema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextFieldForm } from "../../uis/TextField/TextFieldForm";
import { Color } from "../../utils/color";

export const PasswordReset = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // クエリパラメータから email を取得
  const emailParam = searchParams.get("email") ?? "";
  // クエリパラメータが存在していればパスワードリセット確認フォームを表示するフラグ
  const showConfirmForm = !!emailParam;

  // フォーム1: パスワードリセットリクエスト用
  const {
    control: controlRequest,
    handleSubmit: handleSubmitRequest,
    formState: { errors: errorsRequest, isSubmitting: isSubmittingRequest },
  } = useForm<PasswordResetType>({
    resolver: zodResolver(PasswordResetSchema),
  });

  // フォーム2: パスワードリセット確認用
  // defaultValues の email にクエリパラメータをセット
  // ★ ここで resetConfirm を取得するのがポイント
  const {
    control: controlConfirm,
    handleSubmit: handleSubmitConfirm,
    reset: resetConfirm,
    formState: { errors: errorsConfirm, isSubmitting: isSubmittingConfirm },
  } = useForm<PasswordResetConfirmType>({
    resolver: zodResolver(PasswordResetConfirmSchema),
    defaultValues: {
      email: emailParam,
      newPassword: "",
      confirmationCode: "",
    },
  });

  // メールアドレス送信フォームの送信処理
  const onSubmitRequest = async (data: PasswordResetType) => {
    setErrorMessage(null);
    try {
      const result = await auth.forgotPassword(data);
      if (result.success) {
        // URLクエリパラメータを更新
        setSearchParams({ email: data.email });

        // ★ ここで確認フォームの値を更新し、フォームに反映させる
        resetConfirm({
          email: data.email,
          newPassword: "",
          confirmationCode: "",
        });
      } else {
        console.error(result.message);
        setErrorMessage(`${result.message}`);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("パスワードリセットのリクエストに失敗しました");
    }
  };

  // パスワードリセット確認フォームの送信処理
  const onSubmitConfirm = async (data: PasswordResetConfirmType) => {
    setErrorMessage(null);
    try {
      const result = await auth.forgotPasswordConfirm(data);
      if (result.success) {
        // パスワードリセット成功後の処理
        navigate("/signin", { replace: true });
      } else {
        console.error(result.message);
        setErrorMessage(`${result.message}`);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("パスワードのリセットに失敗しました");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: 'url("/background.png")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "80%", md: "50%" },
          backgroundColor: "rgba(242, 242, 242, 0.9)",
          padding: "40px",
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          sx={{ marginBottom: "20px", textAlign: "center", color: Color.Main }}
        >
          パスワードリセット
        </Typography>
        {errorMessage && (
          <Alert severity="error" sx={{ marginBottom: "20px" }}>
            {errorMessage}
          </Alert>
        )}

        {!showConfirmForm ? (
          // フォーム1: パスワードリセットリクエスト
          <form onSubmit={handleSubmitRequest(onSubmitRequest)}>
            <Stack spacing={3}>
              {/* メールアドレス */}
              <TextFieldForm
                name="email"
                control={controlRequest}
                errors={errorsRequest}
                labelName="担当者メールアドレス"
                type="email"
                rules={{
                  required: "メールアドレスは必須です",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "正しいメールアドレスの形式で入力してください",
                  },
                }}
              />
              {/* 送信ボタン */}
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmittingRequest}
                sx={{
                  backgroundColor: Color.Main,
                  color: "#FFFFFF",
                  height: "50px",
                  "&:hover": {
                    backgroundColor: "#006f3d",
                  },
                }}
              >
                {isSubmittingRequest ? "送信中..." : "送信"}
              </Button>
            </Stack>
          </form>
        ) : (
          // フォーム2: パスワードリセット確認
          <form onSubmit={handleSubmitConfirm(onSubmitConfirm)}>
            <Stack spacing={3}>
              {/* メールアドレス（デフォルト値あり） */}
              <TextFieldForm
                name="email"
                control={controlConfirm}
                errors={errorsConfirm}
                labelName="担当者メールアドレス"
                type="email"
                // ここはリクエスト後にresetConfirmで更新される
                rules={{
                  required: "メールアドレスは必須です",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "正しいメールアドレスの形式で入力してください",
                  },
                }}
              />
              {/* 新しいパスワード */}
              <TextFieldForm
                name="newPassword"
                control={controlConfirm}
                errors={errorsConfirm}
                labelName="新しいパスワード"
                type="password"
                rules={{
                  required: "新しいパスワードは必須です",
                  minLength: {
                    value: 8,
                    message: "パスワードは8文字以上に設定してください",
                  },
                  maxLength: {
                    value: 30,
                    message: "パスワードは30文字以下に設定してください",
                  },
                  pattern: {
                    value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,30}$/,
                    message: "パスワードは半角英数字混合で入力してください",
                  },
                }}
              />
              {/* 確認コード */}
              <TextFieldForm
                name="confirmationCode"
                control={controlConfirm}
                errors={errorsConfirm}
                labelName="確認コード"
                type="text"
                rules={{
                  required: "確認コードは必須です",
                  pattern: {
                    value: /^\d{6}$/,
                    message: "確認コードは6桁の数字である必要があります。",
                  },
                }}
              />
              {/* パスワードリセット確認ボタン */}
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmittingConfirm}
                sx={{
                  backgroundColor: Color.Main,
                  color: "#FFFFFF",
                  height: "50px",
                  "&:hover": {
                    backgroundColor: "#006f3d",
                  },
                }}
              >
                {isSubmittingConfirm ? "確認中..." : "パスワードをリセット"}
              </Button>
            </Stack>
          </form>
        )}
      </Box>
    </Box>
  );
};
