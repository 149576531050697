import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { InquirySchema, InquiryType } from "../utils/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGetUser } from "../hooks/useUserHook";
import { useSendInquiry } from "../hooks/useEmailHook";
import { TextField, Button, Box } from "@mui/material";

export const Inquiry = () => {
  const { data: userData } = useGetUser();
  const { mutateAsync, isPending: isMutatePending } = useSendInquiry();

  const {
    control,
    handleSubmit,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm<InquiryType>({
    resolver: zodResolver(InquirySchema),
    defaultValues: {
      email: "",
      companyName: "",
      content: "",
    },
  });

  // 取得したユーザー情報をフォームに反映
  useEffect(() => {
    if (userData) {
      setValue("email", userData.email || "");
      setValue("companyName", userData.company_name || "");
      // 初期フォーカスを設定したい場合は以下を適宜使用
      setFocus("content");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  // フォーム送信時の処理
  const onSubmit = async (data: InquiryType) => {
    try {
      await mutateAsync(data);
      // 送信成功後の処理(アラートやモーダルなど)を適宜追加
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        mt: 2,
        width: "100%",
        maxWidth: 600,
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {/* メールアドレス */}
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="email"
            label="メールアドレス"
            fullWidth
            variant="outlined"
            disabled={isMutatePending}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        )}
      />

      {/* 会社名 */}
      <Controller
        name="companyName"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="会社名"
            fullWidth
            variant="outlined"
            disabled={isMutatePending}
            error={!!errors.companyName}
            helperText={errors.companyName?.message}
          />
        )}
      />

      {/* お問い合わせ内容 */}
      <Controller
        name="content"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="お問い合わせ内容"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            disabled={isMutatePending}
            error={!!errors.content}
            helperText={errors.content?.message}
          />
        )}
      />

      {/* 送信ボタン */}
      <Button type="submit" variant="contained" disabled={isMutatePending}>
        送信
      </Button>
    </Box>
  );
};
