import { useGetLog, useUpdateLog } from "../../../../hooks/Log/useLogHook";
import { CreateItemList } from "../../../common/CreateItemList";

export const CreateLogList = () => {
  return (
    <CreateItemList
      formTitle="丸太リスト作成"
      useGetItem={useGetLog}
      useUpdateItem={useUpdateLog}
      detailsKey="log_details"
      itemName="log"
    />
  );
};
