const config = {
  backendUrl: process.env.REACT_APP_BACKEND_URL,
  backendInquiryUrl: process.env.REACT_APP_BACKEND_INQUIRY_URL,
  cognito: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolClientId: process.env.REACT_APP_AUTH_USER_POOL_CLIENT_ID,
    cookieDomain: process.env.REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN,
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    redirectSignIn: process.env.REACT_APP_AUTH_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_AUTH_REDIRECT_SIGN_OUT,
  },
  kyotoEmail: process.env.REACT_APP_KYOTO_EMAIL,
  miyakoEmail: process.env.REACT_APP_MIYAKO_EMAIL,
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
} as const;

export default config;
