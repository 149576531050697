import React, { useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { Error } from "../../uis/Error";
import { Loading } from "../../uis/Loading";
import { Title } from "../../uis/Title";
import { ItemSearchBox } from "../../uis/Item/ItemSearchBox";
import { FileUpload } from "../../uis/File/FileUpload";
import { BlackButton, WhiteButton } from "../../uis/Button/Button";
import {
  useForm,
  useWatch,
  useFieldArray,
  FieldArrayPath,
  Path,
  PathValue,
  FieldArray,
  UseFormRegister,
  FieldErrors,
  UseFieldArrayAppend,
  FieldArrayWithId,
} from "react-hook-form";
import { UseQueryResult } from "@tanstack/react-query";
import { ReadonlyWood, Wood, WoodDetail } from "../../hooks/Wood/type";
import { Log, LogDetail, ReadonlyLog } from "../../hooks/Log/type";
import { useNavigate, useParams } from "react-router-dom";
import { LogTable } from "../Home/Log/CreateLogList/log_table";
import { WoodTable } from "../Home/InputWoodStock/wood_table";
import {
  ReadonlyItemType,
  useCreateItemListHook,
} from "../../hooks/useCreateItemListHook";
import { OrderStatusWithId } from "../../utils/types/general_type";
import { useSearchItems } from "../../hooks/useItemHook";

export function isLog(
  data?: Readonly<Wood> | Readonly<Log>,
): data is ReadonlyLog {
  return (data as ReadonlyLog).log_details !== undefined;
}

export function isWood(
  data?: Readonly<Wood> | Readonly<Log>,
): data is ReadonlyWood {
  return (data as ReadonlyWood).wood_details !== undefined;
}

/** 木材・丸太の区別 */
type ItemNameType<T extends Wood | Log> = T extends Wood ? "wood" : "log";

/**
 * 各行をテーブルに描画するためのカラム定義。
 * - header: テーブルヘッダに表示する文字列
 * - field: `TDetail` のキー名
 * - placeholder, type, etc.: 入力用に表示したい UI の設定
 */
export interface ColumnDefinition<TDetail> {
  header: string;
  field: keyof TDetail;
  placeholder?: string;
  type?: React.InputHTMLAttributes<unknown>["type"];
}

/**
 * GenericItemList コンポーネントの Props
 */
export interface GenericItemListProps<
  TItem extends Log | Wood,
  TFieldArrayName extends FieldArrayPath<TItem> = FieldArrayPath<TItem>,
> {
  /** 画面タイトルに表示する文言例: "木材リスト作成", "丸太リスト作成" */
  formTitle: string;
  /**
   * データ取得用フック。
   * 例: useGetWood, useGetLog
   * 引数は { id, isOrdered, isTemporarilyStored } などを想定。
   */
  useGetItem: ({
    id,
    isOrdered,
    isTemporarilyStored,
  }: OrderStatusWithId) => UseQueryResult<ReadonlyItemType<TItem>, Error>;
  /**
   * アイテム更新用フック。
   * 例: useUpdateWood, useUpdateLog
   */
  useUpdateItem: () => {
    mutateAsync: (data: TItem) => Promise<any>;
  };
  /**
   * 詳細行の配列キー。
   * 例: "wood_details" | "log_details"
   */
  detailsKey: TFieldArrayName;
  itemName: ItemNameType<TItem>;
}

/**
 * 木材・丸太など、すべて同じ画面フローを扱う共通コンポーネント
 */
export const CreateItemList = <
  TItem extends Log | Wood,
  TDetail extends LogDetail | WoodDetail,
  TFieldArrayName extends FieldArrayPath<TItem> = FieldArrayPath<TItem>,
>({
  /** 画面タイトルに表示する文言例: "木材リスト作成", "丸太リスト作成" */
  formTitle,

  /**
   * データ取得用フック。
   * 例: useGetWood, useGetLog
   * 引数は { id, isOrdered, isTemporarilyStored } などを想定。
   */
  useGetItem,

  /**
   * アイテム更新用フック。
   * 例: useUpdateWood, useUpdateLog
   */
  useUpdateItem,

  /**
   * 詳細行の配列キー。
   * 例: "wood_details" | "log_details"
   */
  detailsKey,
  itemName,
}: GenericItemListProps<TItem, TFieldArrayName>) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    control,
  } = useForm<TItem>(); // フォームの値を管理する
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: detailsKey,
  });

  const { mutateAsync } = useUpdateItem();
  const {
    data: fetchedData,
    error,
    isPending,
    refetch,
  } = useGetItem({
    id: Number(id),
    isOrdered: false,
    isTemporarilyStored: true,
  });

  const watchedDetails = useWatch({
    control,
    name: detailsKey as Path<TItem>,
  }) as TDetail[];

  const {
    data,
    selectedFile,
    isMutatePending,
    areAllDetailsComplete,
    isPolling,
    onSaveTemporarily,
    onSubmit,
    handleFileChange,
    handleFileUpload,
  } = useCreateItemListHook({
    id: Number(id),
    itemName,
    fetchedData,
    watchedDetails,
    refetch,
    mutateAsync,
    navigate,
  });

  // dataの変更時にフォームの値を更新
  // タイトルをsetするため、独自に実装。
  useEffect(() => {
    if (data) {
      setValue(
        "title" as Path<TItem>,
        data?.title as PathValue<TItem, Path<TItem>>,
      );

      const details = isLog(data)
        ? data?.log_details
        : isWood(data)
          ? data?.wood_details
          : [];

      if (details.length > 0) {
        // 1) まずは id を付与 (必須ではないが、標準では useFieldArray が id を必要とする)
        // 2) 最後に "as unknown as FieldArray<...>[]"
        replace(
          details.map((detail) => ({
            ...detail,
            id: crypto.randomUUID?.() ?? String(Math.random()),
          })) as unknown as FieldArray<TItem, TFieldArrayName>[],
        );
      } else {
        replace([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // タイトルをウォッチして一時保存ボタンの disabled 制御に活用
  const watchedTitle = useWatch({
    control,
    name: "title" as Path<TItem>,
  });

  const isWhiteButtonDisabled =
    !watchedTitle &&
    !(
      watchedDetails &&
      watchedDetails[0] &&
      Object.values(watchedDetails[0]).some(
        (value) => value !== "" && value !== 0,
      )
    );

  const isBlackButtonDisabled = !watchedTitle || !areAllDetailsComplete;

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }

  // UI（フォーム、検索、テーブルなど）もすべて共通化
  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      {/* タイトル */}
      <Title title={formTitle} />

      {/* 3. 「丸太モード」の場合は <ItemSearchBox<Log>> を使い、検索関数は Logs を返す */}
      <ItemSearchBox
        id={Number(id)}
        placeholder="一時保存したリスト一覧"
        navigateTo={(id) => navigate(`/order/create_${itemName}_list/${id}`)}
        useSearchItems={useSearchItems}
        itemName={itemName}
      />

      {/* フォーム */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        {/* ファイルアップロード部分 */}
        {isPolling ? (
          "アップロード中...."
        ) : (
          <FileUpload
            selectedFile={selectedFile}
            filePath={data?.file_path}
            handleFileChange={handleFileChange}
            accept={["csv", "xlsx"]}
            message="CSV/Excelファイルを取りこむ"
          />
        )}
        {/* タイトル入力 */}
        <TextField
          placeholder="タイトルを入力"
          variant="outlined"
          fullWidth
          {...register("title" as Path<TItem>)}
          error={!!errors.title}
          helperText={errors.title ? "タイトルを入力してください" : null}
          sx={{
            border: "2px solid black",
            marginBottom: 3,
          }}
        />
        {/* 明細テーブル: 型に応じて LogTable または WoodTable を表示 */}
        {data && (
          <>
            {isLog(data) ? (
              <LogTable
                register={register as unknown as UseFormRegister<Log>}
                errors={errors as unknown as FieldErrors<Log>}
                fields={
                  fields as unknown as FieldArrayWithId<
                    Log,
                    "log_details",
                    "id"
                  >[]
                }
                append={
                  append as unknown as UseFieldArrayAppend<Log, "log_details">
                }
                remove={remove}
              />
            ) : isWood(data) ? (
              <WoodTable
                register={register as unknown as UseFormRegister<Wood>}
                errors={errors as unknown as FieldErrors<Wood>}
                fields={
                  fields as unknown as FieldArrayWithId<
                    Wood,
                    "wood_details",
                    "id"
                  >[]
                }
                append={
                  append as unknown as UseFieldArrayAppend<Wood, "wood_details">
                }
                remove={remove}
              />
            ) : null}
          </>
        )}
        {/* ボタン群 */}
        <WhiteButton
          isSmallScreen
          text="作成内容を一時保存"
          onClick={handleSubmit(onSaveTemporarily)}
          disabled={isWhiteButtonDisabled || isSubmitting}
        />
        <WhiteButton
          isSmallScreen
          text="CSV/Excelファイルをアップロードする"
          onClick={handleFileUpload}
          disabled={!selectedFile || isMutatePending}
        />
        <BlackButton
          isSmallScreen
          text="送付先選択へ"
          type="submit"
          disabled={isBlackButtonDisabled || isSubmitting}
        />
        {/* 合計金額などを表示したい場合 */}
        {itemName === "log" && (
          <Box sx={{ mt: 2 }}>
            <Typography>合計金額: ¥{data?.total_amount}</Typography>
          </Box>
        )}
      </form>
    </Box>
  );
};
