import { useGetBlueprint } from "../../../hooks/Blueprint/useBlueprintHook";
import { useSearchBlueprintUsers } from "../../../hooks/useUserHook";
import { MailingComponent } from "../../common/MailingComponent";

export const BlueprintMailing = () => {
  return (
    <MailingComponent
      idParamName="blueprint_id"
      useGetData={useGetBlueprint}
      useSearchUsers={useSearchBlueprintUsers}
      itemName="blueprint"
    />
  );
};
