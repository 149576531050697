import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { SearchBox } from "../TextField/SearchBox";
import { UseQueryResult } from "@tanstack/react-query";
import { Error } from "../Error";
import { ItemType } from "../../utils/types/item_type";
import { SearchIdRequestWithItemName } from "../../hooks/useItemHook";

interface Item {
  id: number;
  title?: string;
}

interface ItemSearchBoxProps<T extends Item> {
  placeholder: string;
  id: number;
  navigateTo: (id: number) => void;
  useSearchItems({
    searchText,
    limit,
    id,
  }: SearchIdRequestWithItemName): UseQueryResult<ReadonlyArray<T>, Error>;
  itemName?: ItemType;
}

export const ItemSearchBox = <T extends Item>({
  placeholder,
  id,
  navigateTo,
  useSearchItems,
  itemName,
}: ItemSearchBoxProps<T>) => {
  const [searchClicked, setSearchClicked] = useState(false);
  const [searchText, setSearchText] = useState("");

  const { data, error } = useSearchItems({
    searchText,
    id,
    itemName,
  });

  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev);
  };

  const handleSearchBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  if (error) {
    return <Error message={error?.message} />;
  }
  return (
    <>
      <SearchBox
        placeholder={placeholder}
        isSmallScreen
        onClick={handleSearchBoxClick}
        onChange={handleSearchBoxChange}
        value={searchText}
        searchClicked={searchClicked}
      />
      {searchClicked && data && data.length > 0 && (
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            marginTop: 1,
            padding: 2,
          }}
        >
          {data.map((item) => (
            <Typography
              key={item.id}
              sx={{
                padding: "8px 16px",
                borderBottom: "1px solid #e0e0e0",
                "&:hover": { backgroundColor: "#f5f5f5" },
              }}
              onClick={() => navigateTo(item.id)}
            >
              {item.title && item.title !== "" ? item.title : "タイトルなし"}
            </Typography>
          ))}
        </Box>
      )}
    </>
  );
};
