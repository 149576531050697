import { useGetWood, useUpdateWood } from "../../../../hooks/Wood/useWoodHook";
import { CreateItemList } from "../../../common/CreateItemList";

export const CreateWoodList = () => {
  return (
    <CreateItemList
      formTitle="木材リスト作成"
      useGetItem={useGetWood}
      useUpdateItem={useUpdateWood}
      detailsKey="wood_details"
      itemName="wood"
    />
  );
};
