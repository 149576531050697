import React, { useState } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { OrderDetailModal } from "../Modals/OrderDetailModal";
import { ItemType, UsersItem } from "../../utils/types/item_type";
import {
  isBlueprint,
  isLog,
  isWood,
} from "../../pages/common/MailingComponent";
import { BlueprintOrder } from "../../hooks/Blueprint/type";
import { LogOrder } from "../../hooks/Log/type";
import { WoodOrder } from "../../hooks/Wood/type";
import { getUserLists } from "../../utils/user";
import { UserInfo } from "../UserInfo";
import { extractOrders } from "../../utils/extract";
import { useNavigate } from "react-router-dom";
import { Title } from "../Title";
import { conversionItemName } from "../../utils/conversion";
import { useGetItems } from "../../hooks/useItemHook";
import { Loading } from "../Loading";
import { Error } from "../Error";
import { PaginationComponent } from "../Pagination";
import { useSearchAndPaginationHook } from "../../hooks/useSearchAndPaginationHook";
import { FileAndDetailsComponent } from "../FileAndDetailsComponent";

interface ItemListProps {
  isOrderHistory?: boolean;
  itemName: ItemType;
  handleCancelOrder?: (itemId: number) => Promise<void>;
}

export function ItemList({
  isOrderHistory = true,
  itemName,
  handleCancelOrder,
}: ItemListProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [order, setOrder] = useState<UsersItem | null>(null);
  const navigate = useNavigate();

  // カスタムフック
  const { page, handlePageChange } = useSearchAndPaginationHook();

  // API からリスト情報を取得
  const { data, error, isPending } = useGetItems({
    itemName,
    isTemporarilyStored: !isOrderHistory,
    isOrdered: isOrderHistory,
    page,
  });

  if (isPending) return <Loading />;
  if (error) return <Error message={error.message} />;

  return (
    <Box
      sx={{
        // 全体コンテナの高さを固定し、縦長リストをスクロール可能にする
        display: "flex",
        flexDirection: "column",
        width: "90%",
        margin: "0 auto",
        height: "90vh", // 必要に応じて調整
      }}
    >
      {/* タイトル */}
      <Title
        title={`${conversionItemName(itemName)}リスト　${
          isOrderHistory ? "発注" : "一時保存"
        }履歴`}
      />

      {/* リストをスクロールさせるためのコンテナ */}
      <Box
        sx={{
          flex: 1, // 残りの縦スペースをすべて使用
          overflowY: "auto", // ここでスクロールバーを表示
          mt: 2, // タイトルとの余白
        }}
      >
        <List
          sx={{ width: "95%", bgcolor: "background.paper", margin: "0 auto" }}
        >
          {data && data.items.length > 0 ? (
            data.items.map((item, index) => {
              const log = isLog(item) ? item : undefined;
              const wood = isWood(item) ? item : undefined;
              const blueprint = isBlueprint(item) ? item : undefined;

              const itemKeyName = wood
                ? "wood"
                : log
                  ? "log"
                  : blueprint
                    ? "blueprint"
                    : "";

              const orders:
                | readonly (BlueprintOrder | LogOrder | WoodOrder)[]
                | undefined = extractOrders({ wood, log, blueprint });

              const users = log
                ? log.log_orders.map((it) => it.user)
                : wood
                  ? wood.wood_orders.map((it) => it.user)
                  : blueprint
                    ? blueprint.blueprint_orders.map((it) => it.user)
                    : [];

              const {
                isContractingUser,
                isReceivingUsers,
                isPendingUsers,
                isDenyingUsers,
                isApplyingUsers,
              } = getUserLists({ users, wood, log, blueprint });

              return (
                <ListItem
                  key={index}
                  alignItems="flex-start"
                  sx={{
                    borderBottom: "1px solid black",
                    minHeight: "70px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ListItemText
                    primary={
                      log ? (
                        <Box>
                          <Typography variant="h5" sx={{ fontSize: "1.5rem" }}>
                            {log.title ?? "タイトルなし"}
                          </Typography>
                          {log.total_amount !== undefined && (
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              見積もり金額（合計）: ¥
                              {log.total_amount?.toLocaleString()}
                            </Typography>
                          )}
                        </Box>
                      ) : (
                        // カスタムタイトル
                        (wood?.title ??
                        blueprint?.title ??
                        "タイトルが存在しません")
                      )
                    }
                    secondary={
                      isOrderHistory &&
                      (handleCancelOrder && orders && orders.length > 0 ? (
                        orders.every((it) => it.is_active) ? (
                          <Button
                            variant="outlined"
                            onClick={() => handleCancelOrder(item.id)}
                            sx={{
                              borderRadius: "30px",
                              backgroundColor: "#ffffff",
                              color: "#000000",
                              border: "2px solid #000000",
                              padding: "8px 16px",
                              fontSize: "1rem",
                              transition: "all 0.3s",
                              "&:hover": {
                                backgroundColor: "#f5f5f5",
                                borderColor: "#333333",
                              },
                            }}
                          >
                            注文を取り消す
                          </Button>
                        ) : (
                          <Typography>契約済み</Typography>
                        )
                      ) : (
                        <Typography>注文情報なし</Typography>
                      ))
                    }
                    sx={{ width: "35%" }}
                  />

                  {/* ユーザーのセクション表示 */}
                  {isOrderHistory && (
                    <Box sx={{ mb: 4, width: "30%" }}>
                      {isContractingUser && (
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="h6" gutterBottom>
                            契約しているユーザー
                          </Typography>
                          <UserInfo
                            key={isContractingUser.user_id}
                            user={isContractingUser}
                            status="契約中"
                          />
                        </Box>
                      )}

                      {isReceivingUsers.length > 0 && (
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="h6" gutterBottom>
                            受注したユーザー
                          </Typography>
                          {isReceivingUsers.map((user) => (
                            <UserInfo
                              key={user.user_id}
                              user={user}
                              status="受注中"
                            />
                          ))}
                        </Box>
                      )}

                      {isPendingUsers.length > 0 && (
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="h6" gutterBottom>
                            保留中のユーザー
                          </Typography>
                          {isPendingUsers.map((user) => (
                            <UserInfo
                              key={user.user_id}
                              user={user}
                              status="保留中"
                            />
                          ))}
                        </Box>
                      )}

                      {isDenyingUsers.length > 0 && (
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="h6" gutterBottom>
                            拒否したユーザー
                          </Typography>
                          {isDenyingUsers.map((user) => (
                            <UserInfo
                              key={user.user_id}
                              user={user}
                              status="拒否済み"
                            />
                          ))}
                        </Box>
                      )}

                      {isApplyingUsers.length > 0 && (
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="h6" gutterBottom>
                            申請中のユーザー
                          </Typography>
                          {isApplyingUsers.map((user) => (
                            <UserInfo
                              key={user.user_id}
                              user={user}
                              status="申請中"
                            />
                          ))}
                        </Box>
                      )}

                      {/* 表示するユーザーが全くいない場合 */}
                      {!isContractingUser &&
                        [
                          isReceivingUsers,
                          isPendingUsers,
                          isDenyingUsers,
                          isApplyingUsers,
                        ].every((list) => list.length === 0) && (
                          <Typography variant="body1">
                            ユーザーが存在しません。
                          </Typography>
                        )}
                    </Box>
                  )}

                  <Box
                    sx={{
                      width: isSmallScreen ? "70%" : "35%",
                    }}
                  >
                    {/* 詳細・ファイルダウンロードセクション */}
                    {isOrderHistory ? (
                      <FileAndDetailsComponent
                        order={orders?.find(
                          (it) =>
                            !it.is_pending && !it.is_denied && it.is_received,
                        )}
                        wood={wood}
                        log={log}
                        blueprint={blueprint}
                        setOrder={setOrder}
                        isShown={Boolean(
                          orders?.find(
                            (it) =>
                              !it.is_pending && !it.is_denied && it.is_received,
                          ),
                        )}
                        users={users}
                        isSmallScreen={isSmallScreen}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            mr: 1,
                            color: "black",
                            borderColor: "black",
                            backgroundColor: "white",
                            width: "35%",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.1)",
                              borderColor: "black",
                            },
                          }}
                          onClick={() =>
                            setOrder({
                              users: [],
                              item: {
                                wood,
                                log,
                                blueprint,
                              },
                            })
                          }
                        >
                          詳細確認
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            mr: 1,
                            color: "red",
                            borderColor: "red",
                            backgroundColor: "white",
                            width: "35%",
                            "&:hover": {
                              backgroundColor: "rgba(255, 0, 0, 0.1)",
                              borderColor: "red",
                            },
                          }}
                          onClick={() =>
                            navigate(
                              `/order/create_${itemKeyName}_list/${item.id}`,
                            )
                          }
                        >
                          作成を再開
                        </Button>
                      </Box>
                    )}
                  </Box>
                </ListItem>
              );
            })
          ) : (
            // データが無い場合など
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Typography variant="body1" color="textSecondary">
                表示するデータがありません。
              </Typography>
            </Box>
          )}
        </List>
      </Box>

      {/* ページネーションはスクロール領域の外に配置 */}
      {data && data.totalPages > 1 && (
        <PaginationComponent
          totalPages={data.totalPages}
          page={page}
          handlePageChange={handlePageChange}
          isSmallScreen={isSmallScreen}
        />
      )}
      {/* 詳細モーダル */}
      {order !== null && (
        <OrderDetailModal
          userItem={order}
          open={order !== null}
          onClose={() => setOrder(null)}
          isUserShown={isOrderHistory}
        />
      )}
    </Box>
  );
}
