import { Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { BlackButton, WhiteButton } from "../../uis/Button/Button";
import { useForm } from "react-hook-form";
import {
  Notification,
  useGetNotification,
  useUpdateNotification,
  useSearchNotifications,
} from "../../hooks/useNotificationHook";
import {
  SignedURLRequestToPut,
  useGetFileFromS3,
  useUploadFileToS3,
} from "../../hooks/S3/useSignedURLHook";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../uis/Loading";
import { Error } from "../../uis/Error";
import { ItemSearchBox } from "../../uis/Item/ItemSearchBox";
import { FileUpload } from "../../uis/File/FileUpload";

export const NotificationComponent = () => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const { mutateAsync } = useUpdateNotification();
  const { mutateAsync: mutateUploadFileToS3 } = useUploadFileToS3();
  const { notification_id } = useParams<{ notification_id: string }>(); // URLからnotification_idを取得
  const { data, error, isPending } = useGetNotification(
    Number(notification_id),
  );
  const {
    data: imagePath,
    error: getError,
    isLoading: getLoading,
  } = useGetFileFromS3({
    path: data?.image_path,
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }, // isSubmitting を追加
    watch,
    setValue,
  } = useForm<Notification>();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setValue("title", data.title);
      setValue("content", data.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSaveTemporarily = async (data: Notification) => {
    data.id = Number(notification_id); // notification_idを設定
    data.is_temporarily_stored = true;
    data.is_published = false;
    try {
      if (selectedImage) {
        const uploadData = {
          file: selectedImage,
          path: "img/notification",
        } as const satisfies SignedURLRequestToPut;
        const res = await mutateUploadFileToS3(uploadData);
        data.image_path = res;
        await mutateAsync(data);
      } else {
        await mutateAsync(data);
      }
      setSelectedImage(null);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data: Notification) => {
    data.id = Number(notification_id); // notification_idを設定
    data.is_temporarily_stored = false;
    data.is_published = true;
    try {
      if (selectedImage) {
        const uploadData = {
          file: selectedImage,
          path: "img/notification",
        } as const satisfies SignedURLRequestToPut;
        const res = await mutateUploadFileToS3(uploadData);
        data.image_path = res;
        await mutateAsync(data);
      } else {
        await mutateAsync(data);
      }
      navigate("/home");
      setSelectedImage(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
    }
  };

  const watchedTitle = watch("title");
  const watchedContent = watch("content");

  // WhiteButtonの活性化条件: タイトル、内容、または画像のいずれかが存在する場合
  const isWhiteButtonDisabled =
    !watchedTitle && !watchedContent && !selectedImage && !imagePath;

  // BlackButtonの活性化条件: タイトル、内容、画像のすべてが存在する場合
  const isBlackButtonDisabled =
    !watchedTitle || !watchedContent || (!selectedImage && !imagePath);

  if (isPending || getLoading) {
    return <Loading />;
  }

  // エラー時の処理
  if (error || getError) {
    return <Error message={error?.message || getError?.message} />;
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <ItemSearchBox
        placeholder="一時保存した投稿一覧"
        navigateTo={(id) => navigate(`/home/notification/${id}`)}
        id={Number(notification_id)}
        useSearchItems={useSearchNotifications}
      />
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        {/* 画像選択部分 */}
        <FileUpload
          selectedFile={selectedImage}
          filePath={imagePath}
          handleFileChange={handleImageChange}
          accept={["image"]}
          message="画像を選択"
        />
        {/* タイトル入力欄 */}
        <TextField
          placeholder="タイトルを入力"
          variant="outlined"
          fullWidth
          {...register("title")}
          error={!!errors.title}
          helperText={errors.title ? "タイトルを入力してください" : null}
          sx={{
            border: "2px solid black",
            marginBottom: 3,
          }}
        />

        {/* 内容入力欄 */}
        <TextField
          placeholder="内容を入力"
          variant="outlined"
          fullWidth
          multiline
          rows={7}
          {...register("content")}
          error={!!errors.content}
          helperText={errors.content ? "内容を入力してください" : null}
          sx={{
            border: "2px solid black",
            marginBottom: 3,
          }}
        />

        {/* 作成内容を一時保存 ボタン */}
        <WhiteButton
          isSmallScreen
          text={"作成内容を一時保存"}
          onClick={handleSubmit(handleSaveTemporarily)} // handleSaveTemporarily関数を使用
          disabled={isWhiteButtonDisabled || isSubmitting} // ボタンの有効/無効を制御
        />

        {/* お知らせを投稿 ボタン */}
        <BlackButton
          isSmallScreen
          text={"お知らせを投稿"}
          type="submit"
          disabled={isBlackButtonDisabled || isSubmitting} // ボタンの有効/無効を制御
        />
      </form>
    </Box>
  );
};
