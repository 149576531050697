import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
  useTheme,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Users } from "../../hooks/useUserHook";
import { Loading } from "../../uis/Loading";
import { Error } from "../../uis/Error";
import { SearchBox } from "../../uis/TextField/SearchBox";
import Avatar from "../../uis/Avatar";
import { OrderStatusWithId } from "../../utils/types/general_type";
import { Wood } from "../../hooks/Wood/type";
import { Log } from "../../hooks/Log/type";
import { GetBlueprint } from "../../hooks/Blueprint/type";
import { Title } from "../../uis/Title";
import { Talk } from "../../uis/Talk";
import { ItemsRenderDetails } from "../../uis/RenderDetails";
import { ItemEntity, ItemType } from "../../utils/types/item_type";
import { useCreateOrder } from "../../hooks/useOrderHook";
import { UUID } from "crypto";

// 型ガード関数を修正
export function isLog(data: ItemEntity): data is Log {
  return (data as Log).log_details !== undefined;
}

export function isWood(data: ItemEntity): data is Wood {
  return (data as Wood).wood_details !== undefined;
}

export function isBlueprint(data: ItemEntity): data is GetBlueprint {
  return (data as GetBlueprint).blueprint_details !== undefined;
}

interface MailingComponentProps<TData> {
  idParamName: string;
  useGetData: (params: OrderStatusWithId) => {
    data: TData | undefined;
    error: Error | null;
    isPending: boolean;
  };
  useSearchUsers: (params: { searchText: string; limit: number }) => {
    data: Users | undefined;
    error: Error | null;
  };
  itemName: ItemType;
}
// amountのデータに応じて処理する必要がある
export const MailingComponent = <TData extends ItemEntity>({
  idParamName,
  useGetData,
  useSearchUsers,
  itemName,
}: MailingComponentProps<TData>) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [userIds, setUserIds] = useState<UUID[]>([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const params = useParams<{ [key: string]: string }>();
  const idString = params[idParamName];
  const id = Number(idString);
  const { data, error, isPending } = useGetData({
    id,
    isTemporarilyStored: true,
    isOrdered: false,
  });
  const { data: searchUsers, error: searchError } = useSearchUsers({
    searchText,
    limit: 10,
  });

  const { mutateAsync, isPending: isMutatePending } = useCreateOrder();

  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev);
  };

  const handleSearchBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  // 修正: isAll を allSelected に基づいて設定
  const handleOrder = async () => {
    if (data) {
      try {
        await mutateAsync({
          itemName: itemName,
          itemId: data.id,
          isAll: allSelected,
          userIds: userIds,
        });
        navigate("/order");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleAllOrderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && searchUsers) {
      const allUserIds = searchUsers
        .map((user) => user.user_id)
        .filter((id): id is UUID => id !== undefined);
      setUserIds(allUserIds);
    } else {
      setUserIds([]);
    }
  };

  const handleUserCheckboxChange =
    (userId?: UUID) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked && userId) {
        setUserIds((prev) => [...prev, userId]);
      } else {
        setUserIds((prev) => prev.filter((id) => id !== userId));
      }
    };

  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    // "全員に注文" チェックボックスの状態を更新
    if (searchUsers) {
      const allSelectedNow = searchUsers.every((user) =>
        user.user_id ? userIds.includes(user.user_id) : false,
      );
      setAllSelected(allSelectedNow);
    }
  }, [userIds, searchUsers]);

  if (isPending) {
    return <Loading />;
  }

  if (error || searchError) {
    return <Error message={error?.message || searchError?.message} />;
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowDetails(!showDetails)}
        >
          リストの詳細を確認する
        </Button>
      </Box>

      {showDetails && data && (
        <ItemsRenderDetails
          log={isLog(data) ? data : undefined}
          wood={isWood(data) ? data : undefined}
          blueprint={isBlueprint(data) ? data : undefined}
        />
      )}
      <Title title="送信先選択" />
      <SearchBox
        placeholder="会社を検索"
        isSmallScreen={isSmallScreen}
        onClick={handleSearchBoxClick}
        onChange={handleSearchBoxChange}
        value={searchText}
        searchClicked={searchClicked}
      />
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        <ListItem alignItems="flex-start">
          <FormControlLabel
            control={
              <Checkbox
                checked={allSelected}
                onChange={handleAllOrderChange}
                color="primary"
              />
            }
            label="全員に注文"
          />
        </ListItem>
        {searchUsers &&
          searchUsers.length > 0 &&
          searchUsers.map((user) => (
            <ListItem
              key={user.id}
              alignItems="flex-start"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Checkbox
                edge="start"
                checked={user.user_id ? userIds.includes(user.user_id) : false}
                onChange={handleUserCheckboxChange(user.user_id ?? undefined)}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  "aria-labelledby": `checkbox-list-label-${user.id}`,
                }}
              />
              <ListItemAvatar>
                <Avatar user={user} isSmallScreen={isSmallScreen} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body1" component="span">
                      {user.company_name}
                    </Typography>
                    <Talk userId={user.user_id} />
                  </Box>
                }
                secondary={
                  <Typography variant="body2" color="text.secondary">
                    {user.address}
                  </Typography>
                }
              />
            </ListItem>
          ))}
      </List>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
        }}
      >
        {(data?.total_amount && data?.total_amount > 50) ||
        itemName !== "log" ? (
          userIds.length > 0 ? (
            <Button
              variant="contained"
              onClick={handleOrder}
              disabled={isMutatePending}
              sx={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "black",
                },
              }}
            >
              注文する
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled
              sx={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "8px",
              }}
            >
              選択する
            </Button>
          )
        ) : (
          <Typography>
            合計金額は50円以上に設定するようにしてください。
          </Typography>
        )}
      </Box>
    </Box>
  );
};
