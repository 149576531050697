import {
  Box,
  Typography,
  Paper,
  Chip,
  Divider,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Documents,
  Document,
  DocumentResponse,
} from "../../hooks/useDocumentHook";
import { Loading } from "../../uis/Loading";
import { Error as CustomError } from "../../uis/Error";
import { AcceptedCategories, FileDownload } from "../../uis/File/FileDownload";
import { useEffect, useState } from "react";
import { useSendEmail } from "../../hooks/useEmailHook";
import { toast } from "react-toastify";
import { ItemType } from "../../utils/types/item_type";
import config from "../../config";
import { FileUpload } from "../../uis/File/FileUpload";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { PaginationParams } from "../../utils/types/general_type";
import { PaginationComponent } from "../../uis/Pagination";
import { useSearchAndPaginationHook } from "../../hooks/useSearchAndPaginationHook";

interface DocumentListProps {
  category: AcceptedCategories;
  useGetDocuments: ({ page, pageSize }: PaginationParams) => {
    data: DocumentResponse | undefined;
    error: Error | null;
    isPending: boolean;
    refetch: (
      options?: RefetchOptions,
    ) => Promise<QueryObserverResult<DocumentResponse, Error>>;
  };
  isOriginalCertificate?: boolean;
}

interface EmailRecipient {
  name: string;
  email: string;
}

const getTitle = (document: Document): string => {
  switch (document.type) {
    case "wood":
      return document.wood?.title || "無題の木材";
    case "log":
      return document.log?.title || "無題の丸太";
    case "blueprint":
      return document.blueprint?.title || "無題の設計図";
    default:
      return "不明なタイプ";
  }
};

const getTotalVolume = (document: Document): number => {
  switch (document.type) {
    case "wood":
      return (
        document.wood?.wood_details.reduce(
          (acc, detail) => acc + detail.total_volume,
          0,
        ) ?? 0
      );
    case "log":
      return (
        document.log?.log_details.reduce(
          (acc, detail) => acc + detail.total_volume,
          0,
        ) ?? 0
      );
    case "blueprint":
      return (
        document.blueprint?.blueprint_details.reduce(
          (acc, detail) => acc + detail.total_volume,
          0,
        ) ?? 0
      );
    default:
      return 0;
  }
};

const getItemTypeLabel = (type: ItemType): string => {
  switch (type) {
    case "wood":
      return "木材";
    case "log":
      return "丸太";
    case "blueprint":
      return "設計図";
    default:
      return "不明";
  }
};

export const DocumentList = ({
  category,
  useGetDocuments,
  isOriginalCertificate = false,
}: DocumentListProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // カスタムフック
  const { page, handlePageChange } = useSearchAndPaginationHook();

  // データ取得
  const {
    data: fetchedData,
    error,
    isPending,
    refetch,
  } = useGetDocuments({
    page,
  });

  const [documentIdNumber, setDocumentIdNumber] = useState<number | null>(null);
  const [type, setType] = useState<ItemType | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [data, setData] = useState<Documents>([]); // ドキュメントの配列を状態管理
  const { mutateAsync, isPending: isMutatePending } = useSendEmail();
  const [isPolling, setIsPolling] = useState<boolean>(false); // ポーリングの状態管理

  // 初回データ取得時にdataを設定 & status = "pending" があればポーリングを開始
  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData.documents);
      const hasPendingStatus = fetchedData.documents.some((doc) =>
        doc?.delivery_note_emails?.some(
          (email) => email?.status?.status === "pending",
        ),
      );
      if (hasPendingStatus) {
        setIsPolling(true);
      }
    }
  }, [fetchedData]);

  // ポーリング処理: 全てsuccess or 全てfailureで終了
  useEffect(() => {
    let intervalId: number | undefined;

    if (isPolling) {
      intervalId = window.setInterval(async () => {
        try {
          const newData = await refetch();
          if (newData && newData.data?.documents) {
            setData(newData.data.documents);

            // すべてsuccessか、すべてfailureかを判定
            const allSuccess = newData.data.documents.every((document) =>
              document.delivery_note_emails.every(
                (email) => email.status?.status === "success",
              ),
            );

            const allFailure = newData.data.documents.every((document) =>
              document.delivery_note_emails.every(
                (email) => email.status?.status === "failure",
              ),
            );

            if (allSuccess) {
              setIsPolling(false);
            } else if (allFailure) {
              newData.data.documents.forEach((doc) => {
                doc.delivery_note_emails
                  .filter((email) => email.status?.status === "failure")
                  .forEach((email) => {
                    if (email.status?.failure_reason) {
                      toast.error(email.status.failure_reason);
                    }
                  });
              });
              setIsPolling(false);
            }
          }
        } catch (error) {
          console.error(error);
          setIsPolling(false);
        }
      }, 1000);
    }

    return () => {
      if (intervalId !== undefined) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPolling]);

  // メール送付先
  const emailRecipients = [
    {
      name: "京都府産木材(京都府木材連合会)",
      email: config.kyotoEmail!,
    },
    {
      name: "みやこ杣木（京都市域産材供給協会）",
      email: config.miyakoEmail!,
    },
  ] as const satisfies ReadonlyArray<EmailRecipient>;

  // メール送信
  const handleSendEmail = async (email: string) => {
    if (!documentIdNumber || !type || !selectedFile) {
      toast.error("ドキュメントが選択されていません");
      return;
    }
    try {
      await mutateAsync({
        deliveryNoteId: documentIdNumber,
        email,
        type,
        selectedFile,
      });
      setDocumentIdNumber(null);
      setType(null);
      setSelectedFile(null);
    } catch (error) {
      console.error(error);
    }
  };

  // ファイル選択
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <CustomError message={error?.message} />;
  }

  return (
    <Box
      sx={{
        // 全体を縦に配置し、高さを固定
        display: "flex",
        flexDirection: "column",
        width: "80%",
        margin: "0 auto",
        height: "90vh", // 必要に応じて調整
      }}
    >
      {/* スクロール領域 */}
      <Box
        sx={{
          flex: 1, // 上下で余った分だけ使う
          overflowY: "auto", // 縦方向スクロール
          padding: "2rem", // 内側の余白
        }}
      >
        {data && data.length > 0 ? (
          data.map((document, index) => (
            <Paper
              key={index}
              elevation={3}
              sx={{ padding: "1.5rem", marginBottom: "1.5rem" }}
            >
              {/* タイトルとアイテムタイプ */}
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography variant="h5" component="h2" gutterBottom>
                  {getTitle(document)}
                </Typography>
                <Chip
                  label={getItemTypeLabel(document.type)}
                  color="primary"
                  size="small"
                />
              </Box>

              <Divider sx={{ marginBottom: "1rem" }} />

              {/* 受注状況と契約状況 */}
              <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                {document.is_received && (
                  <Grid item>
                    <Chip label="受注済み" color="success" />
                  </Grid>
                )}
                {document.is_contract_signed && (
                  <Grid item>
                    <Chip label="契約済み" color="secondary" />
                  </Grid>
                )}
                {document.is_active && (
                  <Grid item>
                    <Chip label="手続き中" color="info" />
                  </Grid>
                )}
              </Grid>

              <Divider sx={{ marginBottom: "1rem" }} />

              {/* 発注者と受注者 */}
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="subtitle1" component="p">
                    <strong>発注者:</strong>
                    {document.order.company_name || "不明"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1" component="p">
                    <strong>受注者:</strong>
                    {document.receiver.company_name || "不明"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1" component="p">
                    <strong>発行日:</strong>
                    {new Date(document.created_at).toLocaleDateString("ja-JP", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1" component="p">
                    <strong>取引量:</strong>
                    {getTotalVolume(document)}
                  </Typography>
                </Grid>
              </Grid>

              <Divider sx={{ marginBottom: "1rem" }} />

              {/* ファイルダウンロード */}
              <Grid container spacing={2}>
                <FileDownload
                  file={{
                    id: document.document_id,
                    itemType: document.type,
                  }}
                  category={category}
                />
              </Grid>

              {/* オリジナル証明書（産地証明書）の発行 */}
              {isOriginalCertificate && (
                <>
                  <Divider sx={{ marginBottom: "1rem" }} />
                  <Grid container spacing={2}>
                    <Button
                      onClick={() => {
                        setDocumentIdNumber(document.document_id);
                        setType(document.type);
                      }}
                    >
                      産地証明を発行する
                    </Button>
                  </Grid>
                </>
              )}
            </Paper>
          ))
        ) : (
          <Typography variant="h6" component="p" align="center">
            表示するドキュメントがありません。
          </Typography>
        )}
      </Box>

      {/* ページネーション（スクロール領域の外に配置） */}
      {fetchedData && fetchedData.totalPages > 1 && (
        <PaginationComponent
          totalPages={fetchedData.totalPages}
          page={page}
          handlePageChange={handlePageChange}
          isSmallScreen={isSmallScreen}
        />
      )}

      {/* メール送付ダイアログ */}
      <Dialog
        open={documentIdNumber !== null}
        onClose={() => {
          setDocumentIdNumber(null);
          setType(null);
        }}
      >
        <DialogTitle>メール送付先を選択</DialogTitle>
        <FileUpload
          selectedFile={selectedFile}
          filePath={""}
          handleFileChange={handleFileChange}
          accept={["xlsx"]}
          message="CSV/Excelファイルを取りこむ"
        />
        <DialogContent>
          <Grid container spacing={2}>
            {emailRecipients
              .filter((emailRecipient) => {
                const selectedDocument = data.find(
                  (it) => it.document_id === documentIdNumber,
                );
                // 既に送付済みのメールは除外
                return !selectedDocument?.delivery_note_emails.some(
                  (deliveryNoteEmail) =>
                    deliveryNoteEmail.email === emailRecipient.email,
                );
              })
              .map((recipient) => (
                <Grid item xs={12} key={recipient.email}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => handleSendEmail(recipient.email)}
                    disabled={isMutatePending || selectedFile === null}
                  >
                    {recipient.name} ({recipient.email})
                  </Button>
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setType(null);
              setDocumentIdNumber(null);
            }}
            disabled={isMutatePending}
          >
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
