import { useNavigate } from "react-router-dom";
import { MenuItemComponent } from "../../../../uis/Item/MenuItem";
import { useRef, useState } from "react";
import { getCookie } from "../../../../utils/cookie";
import { AdminPasswordModal } from "../../../../uis/Modals/AdminPasswordModal";
import { MANAGEMENT_MONEY_PATH } from "../../../../utils/constant";

export const Money = () => {
  const navigate = useNavigate();
  const submitProcessing = useRef(false); // ① 送信処理を管理するuseRefを作成する
  const [openModal, setOpenModal] = useState<boolean>(false); // モーダル表示の状態
  const [link, setLink] = useState<MenuLink | null>(null); // モーダル表示の状態

  const menuItems = [
    {
      title: "お金",
      items: [
        {
          text: "今月・先月・今年の取引額累計",
          link: `${MANAGEMENT_MONEY_PATH}/transaction_amount`,
        },
        { text: "振込口座登録/編集", link: "/input_shipping" },
        {
          text: "支払い/請求中",
          link: `${MANAGEMENT_MONEY_PATH}/payment`,
        },
      ],
    },
  ] as const;
  type MenuLink = (typeof menuItems)[number]["items"][number]["link"];

  const handleClick = async (link: MenuLink) => {
    if (submitProcessing.current) return; // ② 送信処理中かを判定する（trueなら抜ける）
    const adminSessionId = getCookie("admin_session_id");
    if (!adminSessionId) {
      setOpenModal(true);
      setLink(link);
      return;
    }
    navigate(link);
  };

  const handleSuccessClose = async () => {
    setOpenModal(false);
    if (link) {
      navigate(link);
    }
  };

  const handleErrorClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <MenuItemComponent
        menuItems={menuItems}
        handleClick={handleClick}
        disabled={submitProcessing.current}
      />
      {openModal && (
        <AdminPasswordModal
          open={openModal}
          onSuccessClose={handleSuccessClose}
          onErrorClose={handleErrorClose}
        />
      )}
    </>
  );
};
