import { useQuery } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { documentKeys } from "../utils/query-key";
import { ItemType } from "../utils/types/item_type";
import { Wood } from "./Wood/type";
import { Log } from "./Log/type";
import { BlueprintBase } from "./Blueprint/type";
import { User } from "./useUserHook";
import { PAGE_SIZE, THIRTY_SECONDS } from "../utils/constant";
import { PaginationParams, Status } from "../utils/types/general_type";

export interface Document {
  type: ItemType;
  wood?: Wood;
  log?: Log;
  blueprint?: BlueprintBase;
  document_id: number;
  is_received: boolean;
  is_contract_signed: boolean;
  is_active: boolean;
  order: User;
  receiver: User;
  created_at: Date;
  // 産地証明書の申請ページでのみ使用するプロパティ
  delivery_note_emails: DeliveryNoteEmails[];
}

interface DeliveryNoteEmails {
  email: string;
  file_path: string;
  status?: Status;
}

export type Documents = ReadonlyArray<Document>;

export interface DocumentResponse {
  documents: Documents;
  totalPages: number;
}

export function useGetQuotations({
  page,
  pageSize = PAGE_SIZE,
}: PaginationParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: documentKeys.quotations({ page, pageSize }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("page", page.toString());
      params.append("page_size", pageSize.toString());
      const response = await fetch(
        `${config.backendUrl}/api/quotations?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Readonly<DocumentResponse>>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

export function useGetOrderForms({
  page,
  pageSize = PAGE_SIZE,
}: PaginationParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: documentKeys.orderForms({ page, pageSize }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("page", page.toString());
      params.append("page_size", pageSize.toString());
      const response = await fetch(
        `${config.backendUrl}/api/order_forms?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Readonly<DocumentResponse>>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

export function useGetDeliveryNotes({
  page,
  pageSize = PAGE_SIZE,
}: PaginationParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: documentKeys.deliveryNotes({ page, pageSize }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("page", page.toString());
      params.append("page_size", pageSize.toString());
      const response = await fetch(
        `${config.backendUrl}/api/delivery_notes?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Readonly<DocumentResponse>>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

export function useGetInvoices({
  page,
  pageSize = PAGE_SIZE,
}: PaginationParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: documentKeys.invoices({ page, pageSize }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("page", page.toString());
      params.append("page_size", pageSize.toString());
      const response = await fetch(
        `${config.backendUrl}/api/invoices?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Readonly<DocumentResponse>>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

// 産地証明書の申請ページで使用する関数
export function useGetDeliveryNotesByReceiver({
  page,
  pageSize = PAGE_SIZE,
}: PaginationParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: documentKeys.deliveryNotesByReceiver({ page, pageSize }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("page", page.toString());
      params.append("page_size", pageSize.toString());
      const response = await fetch(
        `${config.backendUrl}/api/delivery_notes/receiver?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Readonly<DocumentResponse>>;
    },
    staleTime: THIRTY_SECONDS,
  });
}
