import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGetOrderPaymentsByRange } from "../../../../hooks/useOrderPaymentHook";
import { Loading } from "../../../../uis/Loading";
import { Error } from "../../../../uis/Error";
import FormField from "../../../../uis/Forms/FormField";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  SearchTransactionAmount,
  SearchTransactionAmountSchema,
} from "../../../../utils/schema";
import { useLocation, useNavigate } from "react-router-dom";

export const TransactionAmount: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // 現在の年月を取得
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0"); // 1-12 を 01-12 に変換

  // デフォルトの開始月と終了月を設定（今年の1月から現在の月まで）
  const defaultStartDate = `${currentYear}-01`;
  const defaultEndDate = `${currentYear}-${currentMonth}`;

  // URLのクエリパラメータを解析
  const getQueryParams = (): SearchTransactionAmount => {
    const params = new URLSearchParams(location.search);
    const startDate = params.get("startDate") || defaultStartDate;
    const endDate = params.get("endDate") || defaultEndDate;
    const type = (params.get("type") as "order" | "receive" | "both") || "both";

    return {
      startDate,
      endDate,
      type,
    };
  };

  // フォームの状態
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<SearchTransactionAmount>({
    resolver: zodResolver(SearchTransactionAmountSchema),
    defaultValues: getQueryParams(),
  });

  // 検索パラメータのステート（初期値を設定）
  const [searchParams, setSearchParams] =
    useState<SearchTransactionAmount>(getQueryParams());

  const { data, error, isPending } = useGetOrderPaymentsByRange(searchParams);

  // フォーム送信ハンドラ
  const onSubmit = (data: SearchTransactionAmount) => {
    setSearchParams(data);

    // クエリパラメータを更新
    const params = new URLSearchParams();
    params.append("startDate", data.startDate);
    params.append("endDate", data.endDate);
    params.append("type", data.type);
    navigate({ search: params.toString() });
  };

  // URLのクエリパラメータが変更された場合にフォームを更新
  useEffect(() => {
    const newParams = getQueryParams();
    setSearchParams(newParams);
    reset(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, reset]);

  // ヘルパー関数で動的に月を生成（現在の月から10年前まで）
  const generateMonthOptions = () => {
    const options = [];
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // 1-12
    const startYear = currentYear - 10;

    for (let year = currentYear; year >= startYear; year--) {
      for (let month = 12; month >= 1; month--) {
        // 現在の年の場合、現在の月まで
        if (year === currentYear && month > currentMonth) continue;
        const value = `${year}-${String(month).padStart(2, "0")}`;
        const label = `${year}年 ${month}月`;
        options.push({ label, value });
      }
    }
    return options;
  };

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error.message} />;
  }
  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>
        取引金額
      </Typography>

      {/* フィルタリングフォーム */}
      <Box component="form" onSubmit={handleSubmit(onSubmit)} mb={4}>
        <Paper elevation={3} sx={{ padding: 3 }}>
          <Grid container spacing={2} alignItems="center">
            {/* 開始月 */}
            <Grid item xs={12} sm={4}>
              <FormField<SearchTransactionAmount>
                register={register}
                name="startDate"
                isError={!!errors.startDate}
                errorMessage={errors.startDate?.message}
                formType="select"
                options={generateMonthOptions()}
                placeholder="開始月を選択してください"
                labelName="開始月"
                defaultValue={defaultStartDate}
              />
            </Grid>
            {/* 終了月 */}
            <Grid item xs={12} sm={4}>
              <FormField<SearchTransactionAmount>
                register={register}
                name="endDate"
                isError={!!errors.endDate}
                errorMessage={errors.endDate?.message}
                formType="select"
                options={generateMonthOptions()}
                placeholder="終了月を選択してください"
                labelName="終了月"
                defaultValue={defaultEndDate}
              />
            </Grid>
            {/* タイプ */}
            <Grid item xs={12} sm={4}>
              <FormField<SearchTransactionAmount>
                register={register}
                name="type"
                isError={!!errors.type}
                errorMessage={errors.type?.message}
                formType="select"
                options={[
                  {
                    label: "支払い側",
                    value: "order",
                  },
                  {
                    label: "受け取り側",
                    value: "receive",
                  },
                  {
                    label: "両方",
                    value: "both",
                  },
                ]}
                placeholder="タイプを選択してください"
                labelName="タイプ"
                defaultValue={"both"}
              />
            </Grid>
            {/* 送信ボタン */}
            <Grid item xs={12} sm={12} textAlign="right">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                sx={{ minWidth: "120px" }}
              >
                検索
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      {data && (
        <>
          {data.length === 0 ? (
            <Typography variant="body1">
              該当するデータがありません。
            </Typography>
          ) : (
            data.map((monthlyPayment) => (
              <Accordion key={`${monthlyPayment.year}-${monthlyPayment.month}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">
                    {monthlyPayment.year}年 {monthlyPayment.month}月
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper variant="outlined" sx={{ overflowX: "auto" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>金額 (円)</TableCell>
                          <TableCell>注文ID</TableCell>
                          <TableCell>アイテムタイプ</TableCell>
                          <TableCell>タイトル</TableCell>
                          <TableCell>パートナー</TableCell>
                          <TableCell>作成日</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {monthlyPayment.payments.map((payment) => (
                          <TableRow key={payment.orderId}>
                            <TableCell>
                              {payment.amount.toLocaleString()}
                            </TableCell>
                            <TableCell>{payment.orderId}</TableCell>
                            <TableCell>{payment.itemType}</TableCell>
                            <TableCell>{payment.title}</TableCell>
                            <TableCell>{payment.partner.email}</TableCell>
                            <TableCell>
                              {new Date(payment.created_at).toLocaleDateString(
                                "ja-JP",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                },
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </>
      )}
    </Box>
  );
};
