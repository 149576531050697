import { useQuery } from "@tanstack/react-query";
import { useAuth } from "./useAuth";
import config from "../config";
import { ItemType } from "../utils/types/item_type";
import { paymentKeys } from "../utils/query-key";
import { handleResponse } from "../utils/response";
import type { SetupIntent as StripeSetupIntent } from "@stripe/stripe-js"; // 型としてインポート
import { User } from "./useUserHook";
import { SearchTransactionAmount } from "../utils/schema";
import { PaginationParams } from "../utils/types/general_type";
import { PAGE_SIZE } from "../utils/constant";

export interface SetupIntent {
  type: ItemType;
  orderId: number;
}
interface SetupIntentResponse {
  setupIntent: StripeSetupIntent;
}
export function useGetSetupIntent(setupIntent: SetupIntent) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: paymentKeys.setupIntent(setupIntent),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(
        `${config.backendUrl}/api/${setupIntent.type}_order_payment`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            orderId: setupIntent.orderId,
          }),
        },
      );
      return handleResponse(response) as Promise<SetupIntentResponse>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

interface Payment {
  amount: number;
  orderId: number;
  itemType: ItemType;
  title: string;
  partner: User;
  created_at: Date;
}
type Payments = ReadonlyArray<Payment>;

interface MonthlyPayment {
  year: number; // 年 (例: 2024)
  month: number; // 月 (1-12)
  payments: Payments;
}
type MonthlyPayments = ReadonlyArray<MonthlyPayment>;

export function useGetProgressOrderPayments({
  page,
  pageSize = PAGE_SIZE,
}: PaginationParams) {
  const { getAccessToken } = useAuth();

  return useQuery({
    queryKey: paymentKeys.progressOrderPayments({ page, pageSize }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = {
        page: page.toString(),
        pageSize: pageSize.toString(),
      } as const;
      // URLSearchParamsでクエリパラメータに変換
      const queryString = new URLSearchParams(params).toString();

      const response = await fetch(
        `${config.backendUrl}/api/progress/order_payments?${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          credentials: "include", // クッキーを含むリクエストを送信するために必要
        },
      );
      return handleResponse(response) as Promise<Payments>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export function useGetOrderPaymentsByRange(params: SearchTransactionAmount) {
  const { getAccessToken } = useAuth();

  return useQuery({
    queryKey: paymentKeys.paymentByRange(params),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const searchParams = new URLSearchParams();
      searchParams.append("startDate", params.startDate);
      searchParams.append("endDate", params.endDate);
      searchParams.append("type", params.type);
      const response = await fetch(
        `${config.backendUrl}/api/order_payments?${searchParams.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          credentials: "include", // クッキーを含むリクエストを送信するために必要
        },
      );
      return handleResponse(response) as Promise<MonthlyPayments>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
