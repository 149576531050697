import { useEffect, useState } from "react";
import { ReadonlyWood, Wood, WoodDetail } from "./Wood/type";
import { Log, LogDetail, ReadonlyLog } from "./Log/type";
import { toast } from "react-toastify";
import { NavigateFunction } from "react-router-dom";
import {
  QueryObserverResult,
  RefetchOptions,
  UseMutateAsyncFunction,
} from "@tanstack/react-query";
import { useUploadFileById } from "./S3/useSignedURLHook";
import { ReadonlyResponse } from "../utils/types/general_type";
import { ItemType } from "../utils/types/item_type";
import {
  BlueprintBase,
  BlueprintDetail,
  ReadonlyGetBlueprint,
} from "./Blueprint/type";

type ItemDataType = Wood | Log | BlueprintBase;
type ItemDetailType = WoodDetail | LogDetail | BlueprintDetail;

type ReadonlyItem = ReadonlyWood | ReadonlyLog | ReadonlyGetBlueprint;
export type ReadonlyItemType<T extends ItemDataType> = T extends Wood
  ? ReadonlyWood
  : T extends Log
    ? ReadonlyLog
    : ReadonlyGetBlueprint;

function isLog(data?: ReadonlyItem): data is ReadonlyLog {
  return (data as ReadonlyLog).log_details !== undefined;
}

function isWood(data?: ReadonlyItem): data is ReadonlyWood {
  return (data as ReadonlyWood).wood_details !== undefined;
}

function isBlueprint(data?: ReadonlyItem): data is ReadonlyGetBlueprint {
  return (data as ReadonlyGetBlueprint).blueprint_details !== undefined;
}

/**
 * カスタムフック用のオプション
 * - fetchedData
 * - refetch
 * - mutateAsync
 * - navigate
 * - details
 * - itemName
 */
interface UseCreateListOptions<
  T extends ItemDataType,
  TDetail extends ItemDetailType,
> {
  id: number;
  itemName: ItemType;
  fetchedData: ReadonlyItemType<T> | undefined;
  watchedDetails: TDetail[];
  refetch: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<ReadonlyItemType<T>, Error>>;
  mutateAsync: UseMutateAsyncFunction<ReadonlyResponse, Error, T, unknown>;
  navigate: NavigateFunction;
}

/**
 * 戻り値
 * - data
 * - onSaveTemporarily
 * - onSubmit
 * - handleFileChange: ファイル変更ハンドラ
 */
interface useCreateListReturn<T extends ItemDataType> {
  data: ReadonlyItemType<T> | null;
  selectedFile: File | null;
  isMutatePending: boolean;
  areAllDetailsComplete: boolean;
  isPolling: boolean;
  onSaveTemporarily: (data: T) => Promise<void>;
  onSubmit: (data: T) => Promise<void>;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileUpload: () => Promise<void>;
}

/**
 * リスト作成を共通化するカスタムフック
 */
export function useCreateItemListHook<
  T extends ItemDataType,
  TDetail extends ItemDetailType,
>({
  id,
  itemName,
  fetchedData,
  watchedDetails,
  refetch,
  mutateAsync,
  navigate,
}: UseCreateListOptions<T, TDetail>): useCreateListReturn<T> {
  // 全明細が空でないかどうかのフラグ
  const areAllDetailsComplete =
    watchedDetails &&
    watchedDetails.length > 0 &&
    watchedDetails.every((detail) =>
      Object.entries(detail).every(
        ([key, value]) =>
          key === "id" || key === "amount" || (value !== "" && value !== 0),
      ),
    );

  // ----- ポーリング関連 -----
  const [isPolling, setIsPolling] = useState(false);

  // ----- 取得データ -----
  const [data, setData] = useState<ReadonlyItemType<T> | null>(null);

  // ----- S3 ファイルアップロード関連 -----
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { mutateAsync: mutateUploadFileToS3, isPending: isMutatePending } =
    useUploadFileById();

  // 初回データ取得時にdataを設定
  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData);
      const status = isLog(fetchedData)
        ? fetchedData?.log_status
        : isWood(fetchedData)
          ? fetchedData?.wood_status
          : isBlueprint(fetchedData)
            ? fetchedData?.blueprint_status
            : undefined;
      if (status?.status === "pending") {
        setIsPolling(true);
      }
    }
  }, [fetchedData]);

  // ----- ポーリング関連 -----
  // アイテムの詳細に関するファイルをアップロードした際に、実装する
  useEffect(() => {
    let intervalId: number; // NodeJS.Timeout から number に変更

    if (isPolling) {
      intervalId = window.setInterval(async () => {
        // window.setInterval を使用
        try {
          const newData = await refetch(); // データを再取得
          if (newData && newData.data) {
            setData(newData.data); // dataを更新
            const status = isLog(newData.data)
              ? newData.data?.log_status
              : isWood(newData.data)
                ? newData.data?.wood_status
                : isBlueprint(newData.data)
                  ? newData.data?.blueprint_status
                  : undefined;
            if (status?.status === "success") {
              toast.success("ファイルを正しく読み込むことができました。");
              setIsPolling(false); // ポーリングを停止
            } else if (status?.status === "failure") {
              toast.error(status.failure_reason);
              setIsPolling(false); // ポーリングを停止
            }
          }
        } catch (error) {
          console.error(error);
          setIsPolling(false); // エラー時もポーリングを停止
        }
      }, 1000); // 1秒ごとに実行
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPolling]);

  // ------ イベントハンドラ群 ------
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile || !data) {
      toast.error("アップロード対象のファイルがありません");
      return;
    }

    try {
      await mutateUploadFileToS3({
        id: data.id,
        file: selectedFile,
        path: `excel_csv/${itemName}`, // 例: "excel_csv/wood"
      });
      setSelectedFile(null);
      setIsPolling(true); // CSV/Excel の取りこみを監視するためポーリング開始
    } catch (error) {
      console.error(error);
    }
  };

  const onSaveTemporarily = async (formData: T) => {
    try {
      formData.id = id;
      formData.is_temporarily_stored = true;
      formData.is_ordered = false;
      await mutateAsync(formData);
      setSelectedFile(null);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (formData: T) => {
    try {
      formData.id = id;
      // 「送付先選択へ」遷移
      await mutateAsync(formData);
      setSelectedFile(null);
      if (itemName !== "blueprint") {
        navigate(`/order/mailing/${itemName}/${id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    data,
    selectedFile,
    isMutatePending,
    areAllDetailsComplete,
    isPolling,
    onSaveTemporarily,
    onSubmit,
    handleFileChange,
    handleFileUpload,
  };
}
